import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { CircularProgress, Snackbar } from '@mui/material'
import { useAuth } from '../auth'
import axios from 'axios'

export default function AddProjectModal(props) {
    const { user } = useAuth()
    const cancelButtonRef = useRef(null)
    const [projectName, setProjectName] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [imageName, setImageName] = useState('')
    const [image, setImage] = useState('')
    const [status, setStatus] = useState({
        loading: false,
        error: false,
        message: '',
    })

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }
        setImage(e.target.files);
        setImageName(e.target.files[0].name)

        // 🚩 do the file upload here normally...
    };

    const editProject = async (event) => {
        event.preventDefault()
        console.log(props.projectName)
        if (projectName === '') {
            setProjectName(props.projectName)
        }
        if (projectDescription === '') {
            setProjectDescription(props.projectDescription)
        }

        try {
            setStatus({ loading: true, error: false, message: '' })
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${props.projectId}`,
                {
                    title: projectName === '' ? props.projectName : projectName,
                    description: projectDescription === '' ? props.projectDescription : projectDescription,
                },
                {
                    headers: {
                        Authorization:
                            `Bearer ${user.user.jwt}`,
                    },
                });
            const project = await response.data;
            if (imageName !== '') {
                console.log(props.projectId)
                const formData = new FormData();
                formData.append('files', image[0], image.name)
                formData.append('ref', 'project')
                formData.append('refId', props.projectId)
                formData.append('field', 'image')
        
                try {
                    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${props.projectId}`,
                        {
                            image: []
                        },
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${user.user.jwt}`,
                            },
                        });
                        const updatedProject = response
                        console.log(updatedProject)
                } catch (e) {
                    console.log(e)
                }
        
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData,
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${user.user.jwt}`,
                            },
                        });
                        const updatedImageProject = response
                        console.log(updatedImageProject)
                } catch (e) {
                    console.log(e)
                }
            }
            setStatus({ loading: false, error: false, message: '' })
            window.location.reload()
        } catch (e) {
        }

    };

    // const uploadImage = async (projectId) => {
    //     console.log('updating image')
    //     console.log(projectId)
    //     const formData = new FormData();
    //     formData.append('files', image[0], image.name)
    //     formData.append('ref', 'project')
    //     formData.append('refId', projectId)
    //     formData.append('field', 'image')

    //     try {
    //         const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
    //             {
    //                 image: []
    //             },
    //             {
    //                 headers: {
    //                     Authorization:
    //                         `Bearer ${user.user.jwt}`,
    //                 },
    //             });
    //             const updatedProject = response
    //             console.log(updatedProject)
    //     } catch (e) {
    //         console.log(e)
    //     }

    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData,
    //             {
    //                 headers: {
    //                     Authorization:
    //                         `Bearer ${user.user.jwt}`,
    //                 },
    //             });
    //             const updatedImageProject = response
    //             console.log(updatedImageProject)
    //     } catch (e) {
    //         console.log(e)
    //     }

    // };

    return (
        <Transition.Root show={props.openEdit} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.isOpenEdit}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className=" w-full md:w-2/3 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Update Project
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <form className="mt-8 space-y-6" onSubmit={editProject}>
                                    <div className="space-y-6 px rounded-md shadow-sm">
                                        <div className="flex justify-center">
                                            <div className='pr-4 w-full'>
                                                <input
                                                    id="project-name"
                                                    name="projectName"
                                                    type="string"
                                                    autoComplete="name"
                                                    className="w-full relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    placeholder={props.projectName}
                                                    value={projectName}
                                                    onChange={(event) => setProjectName(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <textarea
                                                id="project-description"
                                                name="projectDescription"
                                                autoComplete="name"
                                                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                placeholder={props.projectDescription}
                                                value={projectDescription}
                                                onChange={(event) => setProjectDescription(event.target.value)}
                                            />
                                        </div>
                                        <div className='grid grid-cols-12 gap-3'>
                                            <button className='col-span-4 mr-2 inline-flex justify-center border-transparent bg-zuke-blue px-2 py-1 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm'>
                                                upload project image
                                            </button>
                                            <input
                                                type="file"
                                                className="cursor-pointer absolute block opacity-0 pin-r pin-t"
                                                onChange={handleFileChange} />
                                            <div className='col-span-5'>{imageName}</div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 flex flex-col items-center">
                                        {status.loading && <CircularProgress></CircularProgress>}

                                        <button
                                            disabled={status.loading}
                                            type="submit"
                                            className="inline-flex justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm"
                                        >
                                            UPDATE PROJECT
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
