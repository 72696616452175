// Do not edit.
import { ShaderStore } from "../Engines/shaderStore";
import "./ShadersInclude/fogFragmentDeclaration";
import "./ShadersInclude/fogFragment";
import "./ShadersInclude/imageProcessingCompatibility";

const name = "spritesPixelShader";
const shader = `uniform bool alphaTest;varying vec4 vColor;varying vec2 vUV;uniform sampler2D diffuseSampler;#include<fogFragmentDeclaration>
#define CUSTOM_FRAGMENT_DEFINITIONS
#ifdef PIXEL_PERFECT
vec2 uvPixelPerfect(vec2 uv) {vec2 res=vec2(textureSize(diffuseSampler,0));uv=uv*res;vec2 seam=floor(uv+0.5);uv=seam+clamp((uv-seam)/fwidth(uv),-0.5,0.5);return uv/res;}#endif
void main(void) {#define CUSTOM_FRAGMENT_MAIN_BEGIN
#ifdef PIXEL_PERFECT
vec2 uv=uvPixelPerfect(vUV);#else
vec2 uv=vUV;#endif
vec4 color=texture2D(diffuseSampler,uv);float fAlphaTest=float(alphaTest);if (fAlphaTest != 0.){if (color.a<0.95)discard;}color*=vColor;#include<fogFragment>
gl_FragColor=color;#include<imageProcessingCompatibility>
#define CUSTOM_FRAGMENT_MAIN_END
}`;
// Sideeffect
ShaderStore.ShadersStore[name] = shader;
/** @internal */
export const spritesPixelShader = { name, shader };
