import React, { useState, useEffect } from 'react'
import searchIcon from '../../assets/icons/search.svg'
import { PencilIcon } from '@heroicons/react/24/outline'
import moreIcon from '../../assets/icons/MoreIcon.svg'
import qRCodeIcon from '../../assets/icons/qr-code-fill.svg'
import linkIcon from '../../assets/icons/LinkIcon.svg'
import trashIcon from '../../assets/icons/trash.svg'
import copyIcon from '../../assets/icons/CopyIcon.svg'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import {
  CardActionArea,
  Modal,
  Grid,
  Snackbar,
  CircularProgress,
  Switch,
  FormControlLabel
} from '@mui/material'
import { useAuth, logOut, useProject } from '../../auth'
import axios from 'axios'
import { QRCodeSVG } from 'qrcode.react'
import closeIcon from '../../assets/icons/CloseIcon.svg'
import '@fontsource/roboto'
import Divider from '@mui/material/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { minWidth, textAlign } from '@mui/system'
import AddProjectModal from '../../components/AddProjectModal'
import EditProjectModal from '../../components/EditProjectModal'

const classes = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',

  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
  modalLinkText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    justifyContent: 'center',
    marginTop: '24px',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '5px',
  },
  menuText: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  welcomeText: {
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
    paddingLeft: '0',
    marginLeft: '0',
  },
  welcomeTextDiv: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
    paddingLeft: '0',
    marginLeft: '50px',
    marginRight: '40px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
  },
  optionsMenuContainer: {
    paddingLeft: '58px',
    paddingTop: '57px',
  },
  optionsMenuItem: {
    display: 'flex',
    paddingTop: '18px',
    paddingBottom: '18px',
    alignItems: 'center',
  },
  optionsMenuIcon: {
    paddingRight: '10px',
    alignItems: 'baseline',
  },
  optionsMenuTextItem: {
    width: '100%',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    letterSpacing: '0.05em',
    color: '#262626',
    display: 'flex',
    justifyContent: 'left',
  },
  accountMenuContainer: {
    marginTop: '70px',
    paddingLeft: '43px',
  },
  accountMenuItemText: {
    height: '21px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    marginBottom: '33px',
    color: '#262626',
    display: 'flex',
    justifyContent: 'left',
  },
  shareQRText: {
    flexGrow: 1,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
    textOverflow: 'ellipsis',
    marginTop: '40px',
    width: '254px',
    height: '119px',
  },
  linksContainer: {
    marginTop: '60%',
    marginBottom: '7%',
  },
  searchIcon: {
    position: 'relative',
    textAlign: 'right',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    transform: 'translateY(-20%) translateX(-40px)',
  },
  qrContainer: {
    flexGrow: 1,
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  ifDoestWorkText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    marginTop: '49%',
    marginBottom: '7%',
    textAlign: 'center',
  },
  modal: {
    overflowY: 'scroll',
  },
  closeGrid: {
    display: 'flex',
    justifyContent: 'right',
    paddingRight: '20px',
    paddingBottom: '25px',
    background: 'white',
  },
  rightQRContainer: {
    borderLeft: '1px solid hsla(200, 10%, 50%,100)',
    height: '70px',
    color: 'red',
  },
  bottomQRContainer: {
    borderBottom: '1px solid hsla(200, 10%, 50%,100)',
    height: '1px',
    width: '90%',
    backgroundColor: 'white',
  },
  dividerContainerVertical: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dividerContainerHorizontal: {
    backgroundColor: 'white',
    paddingBottom: '35px',
  },
  px: {
    width: '.1%',
    backgroundColor: 'white',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  homePageCardTitleRow: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  followersProfilePic: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  cardBottomIconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardProfileFollowers: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    direction: 'rtl',
    marginRight: '0px',
    paddingRight: '0px',
  },
  cardProfileFollowersCircle: {
    width: '31px',
    height: '31px',
    borderRadius: '50%',
    backgroundColor: '#6b6969',
    objectFit: 'cover',
  },
  tabsContainer: {
    display: 'flex',
    alignSelf: 'baseline',
    justifyContent: 'right',
    marginRight: '10px',
  },
  deleteHelperText: {
    backgroundColor: 'red',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  projectsBody: {
    paddingRight: '20px',
  },
  searchInput: {
    height: '37px',
    background: '#FBF3F9',
    paddingLeft: '16px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
    width: '100%',
    display: 'flex',
    paddingRight: '45px',
  },
  searchInputContainer: {
    display: 'flex',
    height: '37px',
    backgroundColor: 'transparent',
    width: '100%',
  },
  searchInputNavbarContainer: {
    display: 'flex',
    height: '37px',
    backgroundColor: 'transparent',
    width: '100%',
  },
  topGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '26px',
  },
  moreIconContainer: {
    width: '15%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0px',
    paddingRight: '0px',
    alignSelf: 'center',
  },
  cardFooter: {
    display: 'flex',
  },
  shareModalTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: ' #262626',
    backgroundColor: '#ffff',
  },
  shareModalDescription: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    paddingBottom: '60px',
    backgroundColor: '#ffff',
  },
  shareModalDescriptionText: {
    maxWidth: '307px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  shareModalLink: {
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
    paddingBottom: '60px',
    textOverflow: 'ellipsis',
    backgroundColor: '#ffff',
  },
  shareModalLinkText: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
    textOverflow: 'ellipsis',
    marginRight: '10px',
  },
  backButton: {
    height: '43px',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  actionButton: {
    height: '43px',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  snackbar: {
    backgroundColor: '#ffff',
    color: '#ffff',
  },
  deleteModalDescText: {
    maxWidth: '500px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  appbar: {
    marginRight: '13px',
  },
  emptyMessage: {
    marginTop: '30vh',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '22px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
    paddingBottom: '60px',
    textOverflow: 'ellipsis',
  },
  hrLine: {
    width: '90%',
  },
  tabs: {
    display: 'flex',
  },
}


export default function HomePage({ onProjectSelect }) {
  const navigate = useNavigate()
  // const location = useLocation()
  const { user } = useAuth()
  const { projectName } = useProject()
  const [firstName, ...lastName] = user.user.user.name.split(' ')
  const [projects, setProjects] = useState([])
  const [isOpenQRModal, setIsOpenQRModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const [isOpenDragMenu, setIsOpenDragMenu] = useState(false)
  const [isOpenCopySnack, setIsOpenCopySnack] = useState(false)
  const [qr, setQr] = useState('')
  const [link, setLink] = useState('')
  const [title, setTitle] = useState('')
  const [searchText, setSearchText] = useState('')
  const [isFilterProjects, setIsFilterProjects] = useState(false)
  const [projectsToShow, setProjectsToShow] = useState([])
  const [idSelectedProject, setIdSelectedProject] = useState('')
  const [isOpenNotificationMessage, setIsOpenNotificationMessage] =
    useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const isLessThanMD = useMediaQuery('(min-width: 900px)')
  const [isPrivate, setIsPrivate] = useState(true)
  const list = {}
  const [switchState, setSwitchState] = useState({})
  const [item, setItem] = useState({})
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const isOpen = (isOpen) => {
    setOpen(isOpen)
  }

  const isOpenEdit = (isOpenEdit) => {
    setOpenEdit(isOpenEdit)
  }

  const handleChange = (event, checked, projectId) => {
    try {
      const privateProject = axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
        {
          private: checked
        },
        {
          headers: {
            Authorization: `Bearer ${user.user.jwt}`
          },
        })
      setIsPrivate(checked)

    } catch (e) {
      console.log(e)
    }
    list['switch-' + projectId] = checked
    setSwitchState({ ...switchState, ...list })
  }

  const getProjects = async () => {
    try {
      setStatus({ loading: true })
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/projects`,
        {
          headers: {
            Authorization: `Bearer ${user.user.jwt}`,
          },
        }
      )
      const projects = await data
      projects.map(project => {
        list['switch-' + project.id] = project.private

      })
      if (user.user.user.role.id === 3) {
        const viewerProjects = projects.filter((p) => {
          if (p.private !== true)
            return p
        })
        setProjects(viewerProjects)
        setProjectsToShow(viewerProjects)
      } else {
        setProjects(projects)
        setProjectsToShow(projects)
      }
      setSwitchState(list)
      setStatus({ loading: false })

    } catch (error) {
      let errorMessage = error?.response?.data?.message[0]?.messages[0]?.message
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: errorMessage
          ? errorMessage
          : 'An unexpected error has occurred',
      })
    }
  }

  const deleteProject = async (id) => {
    setStatus({ loading: true })
    const data = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/projects/${id}`,
      {
        headers: {
          Authorization: `Bearer ${user.user.jwt}`,
        },
      }
    )
    handleCloseDeleteModal()
    if (data.status === 200) {
      setNotificationMessage('Project deleted successfully')
    } else {
      setNotificationMessage('Error deleting project')
    }
    setIsOpenNotificationMessage(true)
    getProjects()
    setStatus({ loading: false })
  }

  const handleCloseQRModal = () => {
    setIsOpenQRModal(false)
  }

  const handleOpenQRModal = (id) => {
    const url = `https://portal.zukear.com/login?project_id=${id}`
    setQr(url)
    setIsOpenQRModal(true)
  }

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false)
  }

  const handleOpenDeleteModal = (item) => {
    setTitle(item.title)
    setIdSelectedProject(item.id)
    setIsOpenDeleteModal(true)
  }

  const handleCloseShareModal = () => {
    setIsOpenCopySnack(false)
    setIsOpenShareModal(false)
  }

  const handleOpenShareModal = (id) => {
    const link = `https://portal.zukear.com/login?project_id=${id}`
    setLink(link)
    setIsOpenShareModal(true)
  }

  const handleOpenEditProjectModal = (item) => {
    setItem(item)
    setOpenEdit(true)
  }

  const handleCopyLink = (linkToCopy) => {
    navigator.clipboard.writeText(linkToCopy)
    setIsOpenCopySnack(true)
  }

  window.onunload = function () {
    alert('The window is closing now!')
  }

  useEffect(() => {
    getProjects()
  }, [])

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleOnClickDragMenu = () => {
    setIsOpenDragMenu(!isOpenDragMenu)
  }

  const openProject = async (idProject, modelUrl) => {
    onProjectSelect(modelUrl)
    navigate(`/projects/${idProject}/pictures`, { state: { id: idProject, modelUrl: modelUrl } })
  }

  const filterProject = (criteriaFilter) => {
    setSearchText(criteriaFilter)
    if (criteriaFilter !== '') {
      let projectsFilter = projects.filter((item) => {
        return item.title.includes(criteriaFilter)
      })
      setProjectsToShow(projectsFilter)
      setIsFilterProjects(true)
    } else {
      setProjectsToShow(projects)
      setIsFilterProjects(false)
    }
  }

  const accountPage = async () => {
    navigate('/account/')
  }

  return (
    <>
      <AddProjectModal isOpen={isOpen} open={open} />
      <div style={{
        width: '100vw', height: '100vh', flexGrow: 1,
        textAlign: 'center',
        paddingTop: '50hv',
        minWidth: '350px',
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center'
      }}>
        {status.loading ? (
          <div>
            <Grid
              container
              spacing={3}
              paddingLeft='26px'
              paddingRight={'26px'}
              justifyContent={'center'}
              style={{ paddingTop: '30vh' }}
            >
              <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
                <CircularProgress
                  data-cy="loadingProjects"
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid
            container
            spacing={3}
            style={{ paddingTop: '5vh' }}
          >
            <Grid item xs={12} sm={12} md={8.5} ls={8.5} xl={8.5}>
              <Grid
                container
                spacing={3}
                style={{
                  paddingTop: '0vh', display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '26px'
                }}
              >
                {isLessThanMD ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    ls={5}
                    xl={5}
                    display='flex'
                    justifyContent={isLessThanMD ? 'none' : 'center'}
                  >
                  </Grid>
                ) : null}
              </Grid>
              <div style={classes.projectsBody}>
                <div>
                  <Grid
                    container
                    spacing={3}
                    justifyContent={isLessThanMD ? 'none' : 'center'}
                  >
                    {projectsToShow === null || projectsToShow.length === 0 ? (
                      <Grid item xs={10} sm={10} md={12} ls={12} xl={12}>
                        <div
                          style={classes.emptyMessage}
                          data-cy="noProjects">
                          There are no projects to display
                        </div>
                      </Grid>
                    ) : (
                      projectsToShow.slice().map((item, index) => {
                        return (
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={4}
                            ls={3}
                            xl={3}
                            key={index}
                          >
                            <Card
                              sx={{
                                marginBottom: 3,
                                key: index,
                              }}
                              data-cy="projectCard"
                            >
                              <CardActionArea>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  ls={12}
                                  xl={12}
                                  margin='10px'
                                >
                                  <CardMedia
                                    component='img'
                                    width='100%'
                                    style={{
                                      height: isLessThanMD ? '400px' : '400px',
                                      objectFit: 'contain',
                                    }}
                                    image={
                                      item?.image?.[0]?.url
                                        ? item.image[0].url
                                        : require('../../assets/images/project-bg.jpg')
                                    }
                                    onClick={() => openProject(item.id, item.model[0]?.url)}
                                    data-cy="project"
                                  >
                                  </CardMedia>
                                </Grid>
                                <CardContent
                                  sx={{
                                    marginBottom: '0px',
                                    backgroundColor: '#FBF3F9',
                                  }}
                                >
                                  <div style={classes.homePageCardTitleRow}>
                                    <div>
                                      <Typography
                                        component='div'
                                        fontSize='14'
                                        fontStyle='normal'
                                        fontWeight='100'
                                        letterSpacing='0.05'
                                        color='#262626'
                                      >
                                        {item.title}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div style={classes.cardFooter}>
                                    <div
                                      style={classes.cardBottomIconsContainer}
                                    >
                                      <img src={qRCodeIcon} alt="qr code icon"
                                        onClick={() => handleOpenQRModal(item.id)}
                                        data-cy="openQR"
                                      />
                                      <img src={linkIcon}
                                        onClick={() => handleOpenShareModal(item.id)} alt="link icon" data-cy="openLink" />
                                      <img src={trashIcon} className='z-10'
                                        onClick={() => handleOpenDeleteModal(item)} alt="trash icon" />
                                      <div onClick={() => handleOpenEditProjectModal(item)} className="h-2 z-10" >
                                        <PencilIcon className='h-8'></PencilIcon>
                                      </div>

                                    </div>
                                    <div style={classes.cardProfileFollowers}>
                                      {item.users_permissions_users
                                        .slice()
                                        .map((userViewer, index) => {
                                          while (index < 3) {
                                            let translate = index * 10
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  transform: `translateX(${translate}px)`,
                                                  width: '31px',
                                                  height: '31px',
                                                  borderRadius: '50%',
                                                  backgroundColor: '#6b6969',
                                                  objectFit: 'cover',
                                                }}
                                              >
                                                <img
                                                className='rounded-full ring-2 ring-white'
                                                  key={index}
                                                  src={
                                                    userViewer.profilePicture
                                                      ? userViewer.profilePicture.url
                                                      : require('../../assets/images/DefaultUserProfileImage.png')
                                                  }

                                                />
                                                {/* {index} */}
                                              </div>
                                            )
                                          }
                                          return null
                                        })}
                                    </div>
                                  </div>
                                  <div style={classes.cardFooter}>
                                    {user.user.user.role.id !== 3 && <FormControlLabel
                                      control={
                                        <Switch
                                          key={item.id}
                                          checked={switchState['switch-' + item.id]}
                                          onChange={(event, checked) => handleChange(event, checked, item.id)}
                                          data-cy="switchPrivacy"
                                        />}
                                      label={switchState['switch-' + item.id] ? 'private' : 'public'} />}
                                  </div>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        )
                      })
                    )}
                    <Card
                      sx={{
                        margin: 3,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "225px"
                      }}
                    >
                      <CardActionArea
                        sx={{
                          width: "100%",
                          height: "100%"
                        }}
                        onClick={() => setOpen(true)}>
                        <Typography component={'span'}>
                          <p className='font-bold'>Add Project</p>
                        </Typography>
                      </CardActionArea>


                    </Card>
                  </Grid>
                </div>
                <EditProjectModal
                  projectName={item.title}
                  projectDescription={item.description}
                  projectId={item.id}
                  isOpenEdit={isOpenEdit}
                  openEdit={openEdit}
                  data-cy="editProjectModal" />
                <Modal
                  open={isOpenQRModal}
                  onClose={handleCloseQRModal}
                  style={classes.modal}
                  data-cy="QRModal"
                >
                  <div>
                    <Grid
                      container
                      spacing={3}
                      paddingLeft='9%'
                      paddingRight={'6%'}
                      justifyContent={'center'}
                      marginTop={'6%'}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.closeGrid}
                      >
                        <img src={closeIcon} alt='close'
                          onClick={() => setIsOpenQRModal(false)}
                          style={classes.closeIcon}
                        />
                      </Grid>
                      <div style={classes.px}></div>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      paddingLeft='9%'
                      paddingRight={'6%'}
                      justifyContent={'center'}
                      marginBottom={'8%'}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        ls={5}
                        xl={5}
                        backgroundColor='white'
                      >
                        {isLessThanMD ?
                          <div style={classes.shareQRText}>QR Code Share</div> :
                          false
                        }
                        {isLessThanMD ?
                          <div style={classes.linksContainer}>
                            <p style={classes.modalLinkText}>
                              Did you know you can use the QR code for your project? Any user that scans the QR code will be added to the project after setting up their account. Print it on project marketing materials, add it to your business card, or include it in presentations.

                            </p>
                            <p style={classes.modalLinkText}>
                            </p>
                            <p style={classes.modalLinkText}>
                            </p>
                          </div> :
                          false}

                      </Grid>
                      {isLessThanMD ?
                        <div
                          style={
                            !isLessThanMD
                              ? classes.dividerContainerVertical
                              : classes.dividerContainerHorizontal
                          }
                        >
                          <Divider
                            orientation={isLessThanMD ? 'vertical' : 'horizontal'}
                            style={
                              isLessThanMD
                                ? classes.rightQRContainer
                                : classes.bottomQRContainer
                            }
                          />
                        </div> :
                        false
                      }

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        ls={5}
                        xl={5}
                        backgroundColor='white'
                      >
                        <div style={classes.qrContainer}>
                          <QRCodeSVG
                            value={qr}
                            style={{ width: '30vw', height: '30vh' }}
                          />
                        </div>
                        <div>
                          <p style={classes.ifDoestWorkText}>
                            Scan using your device’s camera to view.

                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Modal>
                <Modal
                  open={isOpenDeleteModal}
                  onClose={handleCloseDeleteModal}
                  style={classes.modal}
                  data-cy="deleteModal"
                >
                  <div>
                    <Grid
                      container
                      spacing={3}
                      paddingLeft='9%'
                      paddingRight={'6%'}
                      justifyContent={'center'}
                      marginTop={'6%'}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.closeGrid}
                      >
                        <img src={closeIcon} alt='close'
                          onClick={() => handleCloseDeleteModal()}
                          style={classes.closeIcon}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.shareModalDescription}
                      >
                        <div style={classes.deleteModalDescText}>
                          Are you sure you want to delete ‘{title}’? This item
                          will stay in your recently deleted for one month.
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        ls={5}
                        xl={5}
                        backgroundColor='#ffff'
                        display='flex'
                        justifyContent={isLessThanMD ? 'right' : 'center'}
                        paddingRight={isLessThanMD ? '25px' : '0px'}
                      >
                        <Button
                          variant='outlined'
                          style={classes.backButton}
                          sx={{ textTransform: 'none' }}
                          onClick={() => handleCloseDeleteModal()}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        ls={5}
                        xl={5}
                        backgroundColor='#ffff'
                        display='flex'
                        justifyContent={isLessThanMD ? 'left' : 'center'}
                        paddingLeft={isLessThanMD ? '25px' : '0px'}
                        paddingBottom='90px'
                      >
                        <Button
                          variant='contained'
                          sx={{ textTransform: 'none' }}
                          style={classes.actionButton}
                          onClick={() => deleteProject(idSelectedProject)}
                        >
                          Delete
                        </Button>
                      </Grid>
                      <Snackbar
                        message='Copied to clipboard'
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={2000}
                        onClose={() => setIsOpenCopySnack(false)}
                        open={isOpenCopySnack}
                        bodyStyle={{
                          maxWidth: '130px',
                        }}
                        ContentProps={{
                          sx: {
                            background: '#ffff',
                            color: 'black',
                            width: '100px',
                            display: 'block',
                            textAlign: 'center',
                          },
                        }}
                      />
                    </Grid>
                  </div>
                </Modal>
                <Modal
                  open={isOpenShareModal}
                  onClose={handleCloseShareModal}
                  style={classes.modal}
                  data-cy="shareModal"
                >
                  <div>
                    <Grid
                      container
                      spacing={3}
                      paddingLeft='9%'
                      paddingRight={'6%'}
                      justifyContent={'center'}
                      marginTop={'6%'}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.closeGrid}
                      >
                        <img src={closeIcon} alt='close'
                          onClick={() => handleCloseShareModal()}
                          style={classes.closeIcon}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.shareModalTitle}
                      >
                        Link Share
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.shareModalDescription}
                      >
                        <div style={classes.shareModalDescriptionText}>
                          Copy this link and share it to allow access to your project.
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        ls={10}
                        xl={10}
                        style={classes.shareModalLink}
                      >
                        <div style={classes.shareModalLinkText}>{link}</div>
                        <div>
                          <img src={copyIcon} onClick={() => handleCopyLink(link)} alt="copy icon" />
                        </div>
                        <Snackbar
                          message='Copied to clipboard'
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                          autoHideDuration={2000}
                          onClose={() => setIsOpenCopySnack(false)}
                          open={isOpenCopySnack}
                          bodyStyle={{
                            maxWidth: '130px',
                          }}
                          ContentProps={{
                            sx: {
                              background: '#ffff',
                              color: 'black',
                              width: '100px',
                              display: 'block',
                              textAlign: 'center',
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        ls={5}
                        xl={5}
                        backgroundColor='#ffff'
                        display='flex'
                        justifyContent={isLessThanMD ? 'right' : 'center'}
                        paddingRight={isLessThanMD ? '25px' : '0px'}
                      >
                        <Button
                          variant='outlined'
                          style={classes.backButton}
                          sx={{ textTransform: 'none' }}
                          onClick={() => handleCloseShareModal()}
                        >
                          Go Back
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        ls={5}
                        xl={5}
                        backgroundColor='#ffff'
                        display='flex'
                        justifyContent={isLessThanMD ? 'left' : 'center'}
                        paddingLeft={isLessThanMD ? '25px' : '0px'}
                        paddingBottom='90px'
                      >
                        <Button
                          variant='contained'
                          sx={{ textTransform: 'none' }}
                          style={classes.actionButton}
                          onClick={() => handleCloseShareModal()}
                        >
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Modal>
                <Snackbar
                  message={notificationMessage}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  autoHideDuration={2000}
                  onClose={() => setIsOpenNotificationMessage(false)}
                  open={isOpenNotificationMessage}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  )
}
