import React, { useState, useEffect } from 'react'
import downloadIcon from '../../assets/icons/DownloadIcon.svg'
import linkIcon from '../../assets/icons/LinkIcon.svg'
import trashIcon from '../../assets/icons/trash.svg'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { Modal, Grid, Snackbar, CircularProgress } from '@mui/material'
import { useAuth } from '../../auth'
import axios from 'axios'
import { QRCodeSVG } from 'qrcode.react'
import closeIcon from '../../assets/icons/CloseIcon.svg'
import '@fontsource/roboto'
import Divider from '@mui/material/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import { useLocation, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import copyIcon from '../../assets/icons/CopyIcon.svg'
import ReactPlayer from 'react-player'
import Viewers from '../../components/Viewers'
import ModelViewer from '../../components/ModelViewer.js'
import AddViewer from '../../components/AddViewer'
import Group16 from '../../assets/group16'
import Vector6 from '../../assets/group6'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const classes = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',

  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  modalLinkText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    justifyContent: 'center',
    marginTop: '24px',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '5px',
  },
  menuText: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  welcomeText: {
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
    paddingLeft: '0',
    marginLeft: '0',
  },
  welcomeTextDiv: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
    paddingLeft: '0',
    marginLeft: '50px',
    marginRight: '40px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
  },
  optionsMenuContainer: {
    paddingLeft: '58px',
    paddingTop: '57px',
  },
  optionsMenuItem: {
    display: 'flex',
    paddingTop: '18px',
    paddingBottom: '18px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  optionsMenuIcon: {
    paddingRight: '10px',
    alignItems: 'baseline',
  },
  optionsMenuTextItem: {
    width: '100%',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    letterSpacing: '0.05em',
    color: '#262626',
    display: 'flex',
    justifyContent: 'left',
  },
  projectLateralButtonsContainer: {
    display: 'block',
    justifyContent: 'space-evenly',
    paddingLeft: '58px',
    paddingRight: '8%',
    paddingTop: '90px',
  },
  editProjectButton: {
    width: '100%',
    height: '43px',
    color: 'white',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  flashToolButton: {
    width: '100%',
    height: '43px',
    marginTop: '30px !important',
  },
  selectionTitle: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  selectionDescription: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'center',
    letterSpacing: '0.05em',
    color: '#262626',
    marginBottom: '40px',
  },
  shareQRText: {
    flexGrow: 1,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
    textOverflow: 'ellipsis',
    marginTop: '40px',
    width: '254px',
    height: '119px',
  },
  linksContainer: {
    marginTop: '60%',
    marginBottom: '7%',
  },
  qrContainer: {
    flexGrow: 1,
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  ifDoestWorkText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    marginTop: '49%',
    marginBottom: '7%',
    textAlign: 'center',
  },
  modal: {
    overflowY: 'scroll',
  },
  contentModalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'scroll',
  },
  showContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageDiv: {
    height: '300px',
    width: '300px',
    display: 'flex',
    justifyContent: 'center',
  },
  closeGrid: {
    display: 'flex',
    justifyContent: 'right',
    paddingRight: '20px',
    paddingBottom: '25px',
    background: 'white',
  },
  rightQRContainer: {
    borderLeft: '1px solid hsla(200, 10%, 50%,100)',
    height: '70px',
  },
  bottomQRContainer: {
    borderBottom: '1px solid hsla(200, 10%, 50%,100)',
    height: '1px',
    width: '90%',
    backgroundColor: 'white',
  },
  dividerContainerVertical: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dividerContainerHorizontal: {
    backgroundColor: 'white',
    paddingBottom: '35px',
  },
  px: {
    width: '.1%',
    backgroundColor: 'white',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  homePageCardTitleRow: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'center',
  },
  followersProfilePic: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  cardBottomIconsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardProfileFollowers: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    direction: 'rtl',
    marginRight: '0px',
    paddingRight: '0px',
  },
  cardProfileFollowersCircle: {
    width: '31px',
    height: '31px',
    borderRadius: '50%',
    backgroundColor: '#6b6969',
    objectFit: 'cover',
  },
  tabsContainer: {
    display: 'flex',
    alignSelf: 'baseline',
    justifyContent: 'right',
    marginRight: '10px',
  },
  deleteHelperText: {},
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  projectsBody: {
    paddingRight: '20px',
  },
  searchInput: {
    height: '37px',
    background: '#FBF3F9',
    paddingLeft: '16px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
    width: '100%',
    display: 'flex',
  },
  searchInputContainer: {
    display: 'flex',
    height: '37px',
    backgroundColor: 'transparent',
    width: '100%',
  },
  searchInputNavbarContainer: {
    display: 'flex',
    height: '37px',
    backgroundColor: 'transparent',
    width: '100%',
  },
  topGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '26px',
  },
  moreIconContainer: {
    width: '15%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0px',
    paddingRight: '0px',
    alignSelf: 'center',
  },
  cardFooter: {
    display: 'flex',
  },
  shareModalTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: ' #262626',
    backgroundColor: '#ffff',
  },
  shareModalDescription: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    paddingBottom: '60px',
    backgroundColor: '#ffff',
  },
  shareModalDescriptionText: {
    maxWidth: '307px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  shareModalLink: {
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
    paddingBottom: '60px',
    textOverflow: 'ellipsis',
    backgroundColor: '#ffff',
  },
  shareModalLinkText: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
    textOverflow: 'ellipsis',
    marginRight: '10px',
  },
  backButton: {
    height: '43px',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  actionButton: {
    height: '43px',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  snackbar: {
    backgroundColor: '#ffff',
    color: '#ffff',
  },
  deleteModalDescText: {
    maxWidth: '500px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  photoTitleModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '28px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  hrLine: {
    width: '90%',
  },
  emptyMessage: {
    marginTop: '20vh',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '22px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
    paddingBottom: '60px',
    textOverflow: 'ellipsis',
  },
  contentModalMainContainer: {
    height: '80vh',
    width: '80vw',
  },
  contentModalTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '28px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
    overflowX: 'clip',
  },
  contentModalImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
  },
  contentModalButtonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}

export default function Project({props}) {
  const params = useParams()
  const { user } = useAuth()
  const [projectInfo, setProjectInfo] = useState([])
  const [arrayData, setArrayData] = useState([])
  const [isOpenQRModal, setIsOpenQRModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenContentModal, setIsOpenContentModal] = useState(false)
  const [isOpenCopySnack, setIsOpenCopySnack] = useState(false)
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const [link, setLink] = useState('')
  const [idSelectedProject, setIdSelectedProject] = useState('')
  const [selectedOption, setSelectedOption] = useState('Pictures')
  const [contentUrl, setContentUrl] = useState('')
  const photosLen = arrayData.length
  const [elementIndex, setElementIndex] = useState(0)
  const [isDisableGaleyNextButton, setIsDisableGaleyNextButton] = useState(false)
  const [isDisableGaleyBackButton, setIsDisableGaleyBackButton] = useState(false)
  const [title, setTitle] = useState('')
  const { id } = useParams()
  const [qr, setQr] = useState('')
  const isLessThanMD = useMediaQuery('(min-width: 900px)')
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })

  const onCloseSnack = () => {
    setStatus({
      message: '',
      error: false,
      isOpenSnackStatus: false,
    })
  }

  const deleteItem = async (idSelectedProject) => {
    try {
      setStatus({ loading: true })
      const toKeepIdArray = arrayData.filter((item) => {
        return item.id !== idSelectedProject
      })
      const newDelete = toKeepIdArray.map((item) => {
        let id = item.id
        return { id }
      })
      let body = {}

        body = {
          photoTour: newDelete,
        }

      const data = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/projects/${id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${user.user.jwt}`,
          },
        }
      )
      handleCloseDeleteModal()
      // await getProjectInfo()
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: 'Project deleted successfully',
      })
    } catch (error) {
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: 'Error deleting project',
      })
    }
  }

  const handleCloseQRModal = () => {
    setIsOpenQRModal(false)
  }

  // const handleOpenQRModal = (id) => {
  //   let uri =
  //     'https://zukurri.s3.us-east-2.amazonaws.com/Construcciones_y_Reformas_1100x511_3ac8c3de98.png'
  //   let link = document.createElement('a')
  //   link.download = name
  //   link.href = uri
  //   link.click()
  // }

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false)
  }

  const handleOpenDeleteModal = (item) => {
    setTitle(item.name)
    setIdSelectedProject(item.id)
    setIsOpenDeleteModal(true)
  }

  const handleCloseShareModal = () => {
    setIsOpenShareModal(false)
  }

  const handleOpenShareModal = (id) => {
    const link = `${
      window.location.href
    }/${selectedOption.toLocaleLowerCase()}/${id}`
    setLink(link)
    setIsOpenShareModal(true)
  }

  const handleCopyLink = (linkToCopy) => {
    navigator.clipboard.writeText(linkToCopy)
    setIsOpenCopySnack(true)
  }

  const handleCloseContentModal = () => {
    setIsOpenContentModal(false)
  }

  const handleOpenContentModal = (url, index) => {
    setElementIndex(index)
    disableGaleyButtons(index)
    setContentUrl(url)
    setIsOpenContentModal(true)
  }

  const handleOnClickPictureOption = (e) => {
    setSelectedOption('Pictures')
    setArrayData(arrayData)
  }

  const getName = (array, id) => {
    let nameComplete = array[id].name
    let name = nameComplete.split('.')[0]
    return name
  }

  const navigateGalleryNext = () => {
    if (elementIndex === photosLen - 2) {
      setIsDisableGaleyNextButton(true)
    }

    if (elementIndex >= 0) {
      setIsDisableGaleyBackButton(false)
    }

    if (elementIndex <= photosLen - 2) {
      setElementIndex(elementIndex + 1)
    }
  }

  const navigateGalleryBack = () => {
    if (elementIndex === 1) {
      setIsDisableGaleyBackButton(true)
    }

    if (elementIndex <= photosLen - 1) {
      setIsDisableGaleyNextButton(false)
    }

    if (elementIndex > 0) {
      setElementIndex(elementIndex - 1)
    }
  }

  const disableGaleyButtons = (index) => {
    if (index <= 0) {
      setIsDisableGaleyBackButton(true)
    } else {
      setIsDisableGaleyBackButton(false)
    }

    if (index === photosLen - 1) {
      setIsDisableGaleyNextButton(true)
    } else {
      setIsDisableGaleyNextButton(false)
    }
  }

  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        setStatus({ loading: true })
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/projects/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user.user.jwt}`,
            },
          }
        )
        setStatus({ loading: false })
        const projectInfo = await data
          setArrayData(projectInfo.photoTour)
      } catch (error) {
        let errorMessage =
          error?.response?.data?.message?.[0]?.messages?.[0]?.message
        setStatus({
          loading: false,
          isOpenSnackStatus: true,
          message: errorMessage
            ? errorMessage
            : 'An unexpected error has occurred',
        })
      }
    }
    getProjectInfo()
  }, [])


  return (
    <div style={{ flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center'
    }}>
      {status.loading ? (
          <Grid
            container
            spacing={3}
            paddingLeft='26px'
            paddingRight={'26px'}
            justifyContent={'center'}
            style={{ paddingTop: '30vh' }}
          >
            <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
              <CircularProgress />
            </Grid>
          </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          justifyContent={'center'}
          style={{ paddingTop: '5vh' }}
        >
          <Grid item xs={12} sm={12} md={8.5} ls={12} xl={12}>
            <Grid
              container
              spacing={3}
              justifyContent='center'
              style={{ paddingTop: '0vh', display: 'flex',
              alignItems: 'center',
              marginBottom: '26px' }}
            >
              {isLessThanMD ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  ls={12}
                  xl={12}
                  display='block'
                  justifyContent='center'
                >
                  <div style={classes.selectionTitle}>
                    All {selectedOption}
                  </div>
                  <div style={classes.selectionDescription}>
                    Below is a gallery of pictures to showcase your project. You
                    can download them individually, or save them as a bundle by
                    selecting multiple.
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <div style={classes.projectsBody}>
              <div>
                <Grid
                  container
                  spacing={3}
                  justifyContent={isLessThanMD ? 'center' : 'center'}
                >
                  {arrayData === null || arrayData.length === 0 ? (
                    <Grid item xs={10} sm={10} md={12} ls={12} xl={12}>
                      <div style={classes.emptyMessage}>
                        There are no elements to display
                      </div>
                    </Grid>
                  ) : (
                    arrayData.slice().map((item, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={12}
                          md={12}
                          ls={12}
                          xl={12}
                        >
                          <Card
                            sx={{
                              marginBottom: 3,
                              key: index,
                              display: 'flex',
                              minWidth: isLessThanMD ? '260px' : '330px',
                            }}
                            key={index}
                          >
                            <Box
                              sx={{ display: 'flex', flexDirection: 'column', minWidth: '50px', maxWidth: '75px' }}
                            >
                              <CardContent
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  height: '100%',
                                }}
                              >
                                <img src={downloadIcon}
                                  // onClick={() => handleOpenQRModal(item.id)}
                                  style={classes.cursorPointer}
                                />
                                <img src={linkIcon}
                                  onClick={() => handleOpenShareModal(item.id)}
                                  style={classes.cursorPointer}
                                />
                                <img src={trashIcon}
                                  onClick={() => handleOpenDeleteModal(item)}
                                  style={classes.cursorPointer}
                                />
                              </CardContent>
                            </Box>
                            <CardMedia
                              component='img'
                              width='100%'
                              height='100%'
                              style={{
                                height: isLessThanMD ? '230px' : '300px',
                                objectFit: 'contain',
                                padding: '5px',
                                paddingRight: '70px', 
                                cursor: 'pointer'
                              }}
                              image={
                                item.url
                                  ? item.url
                                  : require('../../assets/images/ProjectImage.png')
                              }
                              onClick={() =>
                                handleOpenContentModal(item.url, index)
                              }
                            ></CardMedia>
                          </Card>
                        </Grid>
                      )
                    })
                  )}
                </Grid>
              </div>
              <Modal
                open={isOpenQRModal}
                onClose={handleCloseQRModal}
                style={classes.modal}
              >
                <div>
                  <Grid
                    container
                    spacing={3}
                    paddingLeft='9%'
                    paddingRight={'6%'}
                    justifyContent={'center'}
                    marginTop={'6%'}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.closeGrid}
                    >
                      <img src='closeIcon'
                        onClick={() => setIsOpenQRModal(false)}
                        style={classes.closeIcon}
                      />
                    </Grid>
                    <div style={classes.px}></div>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    paddingLeft='9%'
                    paddingRight={'6%'}
                    justifyContent={'center'}
                    marginBottom={'8%'}
                  >
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      ls={5}
                      xl={5}
                      backgroundColor='white'
                    >
                      <div style={classes.shareQRText}>QR Code Share</div>
                      <div style={classes.linksContainer}>
                        <p style={classes.modalLinkText}>
                          Open the Zuke AR App.
                        </p>
                        <p style={classes.modalLinkText}>
                          Sign in and click on the QR Code Symbol.
                        </p>
                        <p style={classes.modalLinkText}>
                          Scan using your device’s camera to view.
                        </p>
                      </div>
                    </Grid> */}
                    <div
                      style={
                        !isLessThanMD
                          ? classes.dividerContainerVertical
                          : classes.dividerContainerHorizontal
                      }
                    >
                      <Divider
                        orientation={isLessThanMD ? 'vertical' : 'horizontal'}
                        style={
                          isLessThanMD
                            ? classes.rightQRContainer
                            : classes.bottomQRContainer
                        }
                      />
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      ls={5}
                      xl={5}
                      backgroundColor='white'
                    >
                      <div style={classes.qrContainer}>
                        <QRCodeSVG
                          value={qr}
                          style={{ width: '30vw', height: '30vh' }}
                        />
                      </div>
                      {/* <div>
                        <p style={classes.ifDoestWorkText}>
                          If scan does not work, type in the code seen here.
                        </p>
                      </div> */}
                    </Grid>
                  </Grid>
                </div>
              </Modal>
              <Modal
                open={isOpenDeleteModal}
                onClose={handleCloseDeleteModal}
                style={classes.modal}
              >
                <div>
                  <Grid
                    container
                    spacing={3}
                    paddingLeft='9%'
                    paddingRight={'6%'}
                    justifyContent={'center'}
                    marginTop={'6%'}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.closeGrid}
                    >
                      <img src='closeIcon'
                        onClick={() => setIsOpenDeleteModal(false)}
                        style={classes.closeIcon}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.shareModalDescription}
                    >
                      <div style={classes.deleteModalDescText}>
                        Are you sure you want to delete ‘{title}’? This item
                        will stay in your recently deleted for one month.
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      ls={5}
                      xl={5}
                      backgroundColor='#ffff'
                      display='flex'
                      justifyContent={isLessThanMD ? 'right' : 'center'}
                      paddingRight={isLessThanMD ? '25px' : '0px'}
                    >
                      <Button
                        variant='outlined'
                        style={classes.backButton}
                        sx={{ textTransform: 'none' }}
                        onClick={handleCloseDeleteModal}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      ls={5}
                      xl={5}
                      backgroundColor='#ffff'
                      display='flex'
                      justifyContent={isLessThanMD ? 'left' : 'center'}
                      paddingLeft={isLessThanMD ? '25px' : '0px'}
                      paddingBottom='90px'
                    >
                      <Button
                        variant='contained'
                        sx={{ textTransform: 'none' }}
                        style={classes.actionButton}
                        onClick={() => deleteItem(idSelectedProject)}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Snackbar
                      message='Copied to clipboard'
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      autoHideDuration={2000}
                      onClose={() => setIsOpenCopySnack(false)}
                      open={isOpenCopySnack}
                      bodyStyle={{
                        maxWidth: '130px',
                      }}
                      ContentProps={{
                        sx: {
                          background: '#ffff',
                          color: 'black',
                          width: '100px',
                          display: 'block',
                          textAlign: 'center',
                        },
                      }}
                    />
                  </Grid>
                </div>
              </Modal>
              <Modal
                open={isOpenShareModal}
                onClose={handleCloseShareModal}
                style={classes.modal}
              >
                <div>
                  <Grid
                    container
                    spacing={3}
                    paddingLeft='9%'
                    paddingRight={'6%'}
                    justifyContent={'center'}
                    marginTop={'6%'}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.closeGrid}
                    >
                      <img src='closeIcon'
                        onClick={() => setIsOpenShareModal(false)}
                        style={classes.closeIcon}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.shareModalTitle}
                    >
                      Link Share
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.shareModalDescription}
                    >
                      <div style={classes.shareModalDescriptionText}>
                        Copy this link and share it. You can reaccess it at
                        anytime in the calendar tab on the main menu.
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      ls={10}
                      xl={10}
                      style={classes.shareModalLink}
                    >
                      <div style={classes.shareModalLinkText}>{link}</div>
                      <div>
                        <img src={copyIcon} onClick={() => handleCopyLink(link)} />
                      </div>
                      <Snackbar
                        message='Copied to clipboard'
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={2000}
                        onClose={() => setIsOpenCopySnack(false)}
                        open={isOpenCopySnack}
                        bodyStyle={{
                          maxWidth: '130px',
                        }}
                        ContentProps={{
                          sx: {
                            background: '#ffff',
                            color: 'black',
                            width: '100px',
                            display: 'block',
                            textAlign: 'center',
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      ls={5}
                      xl={5}
                      backgroundColor='#ffff'
                      display='flex'
                      justifyContent={isLessThanMD ? 'right' : 'center'}
                      paddingRight={isLessThanMD ? '25px' : '0px'}
                    >
                      <Button
                        variant='outlined'
                        style={classes.backButton}
                        sx={{ textTransform: 'none' }}
                        onClick={handleCloseShareModal}
                      >
                        Go Back
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      ls={5}
                      xl={5}
                      backgroundColor='#ffff'
                      display='flex'
                      justifyContent={isLessThanMD ? 'left' : 'center'}
                      paddingLeft={isLessThanMD ? '25px' : '0px'}
                      paddingBottom='90px'
                    >
                      <Button
                        variant='contained'
                        sx={{ textTransform: 'none' }}
                        onClick={handleCloseShareModal}
                        style={classes.actionButton}
                      >
                        Done
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Modal>
              <Modal
                open={isOpenContentModal}
                onClose={handleCloseContentModal}
                style={classes.contentModalContainer}
              >
                <Grid
                  container
                  spacing={3}
                  justifyContent={'center'}
                  display='flex'
                  margin='20px'
                  backgroundColor='white'
                  style={{ width: '100vw', height: '95vh' }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    ls={12}
                    xl={12}
                    style={classes.closeGrid}
                  >
                    <img src='closeIcon'
                      onClick={() => setIsOpenContentModal(false)}
                      style={classes.closeIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    ls={12}
                    xl={12}
                    style={classes.showContentContainer}
                    paddingLeft={selectedOption === 'Videos' ? '5%' : '0px'}
                  >
                    <div>
                      {selectedOption === 'Pictures' ? (
                        <div style={classes.contentModalMainContainer}>
                          <div style={classes.contentModalTitleContainer}>
                            {arrayData[elementIndex] &&
                            arrayData[elementIndex].name
                              ? getName(arrayData, elementIndex)
                              : ''}
                          </div>
                          <div style={classes.contentModalImageContainer}>
                            <img
                              src={arrayData?.[elementIndex]?.url}
                              style={{
                                objectFit: 'contain',
                              }}
                            ></img>
                          </div>
                          <div style={classes.contentModalButtonContainer}>
                            <Button
                              onClick={navigateGalleryBack}
                              disabled={isDisableGaleyBackButton}
                            >
                              <KeyboardArrowLeft />
                              back
                            </Button>
                            <Button
                              onClick={navigateGalleryNext}
                              disabled={isDisableGaleyNextButton}
                            >
                              next
                              <KeyboardArrowRight />
                            </Button>
                          </div>
                        </div>
                      ) : selectedOption === 'Videos' ? (
                        <ReactPlayer
                          url={contentUrl}
                          controls={true}
                          playing={true}
                          height='100%'
                          width='95%'
                        />
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </Modal>
            </div>
          </Grid>
        </Grid>
      )}
      <Snackbar
        message={status.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        onClose={() => onCloseSnack(false)}
        open={status.isOpenSnackStatus}
      />
    </div>
  )
}
