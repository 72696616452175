import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { CircularProgress, Snackbar } from '@mui/material'
import { useAuth } from '../auth'
import axios from 'axios'

export default function AddProjectModal(props) {
    const { user } = useAuth()
    const cancelButtonRef = useRef(null)
    const [imageName, setImageName] = useState('')
    const [image, setImage] = useState('')
    const [status, setStatus] = useState({
        loading: false,
        error: false,
        message: '',
    })

    const handleFileChange = (e) => {
        console.log(e)
        if (!e.target.files) {
            return;
        }
        setImage(e.target.files);
        setImageName(e.target.files[0].name)

        // 🚩 do the file upload here normally...
    };

    const uploadProfilePicture = async (e) => {
        e.preventDefault()
        console.log(user.user.user.id)
        console.log(imageName)
        const formData = new FormData();
        formData.append('files', image[0], image.name)
        formData.append('ref', 'plugins::users-permissions.user')
        formData.append('refId', user.user.user.id)
        formData.append('field', 'profilePicture')

        try {
            setStatus({loading: true})
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData,
                {
                    headers: {
                        Authorization:
                            `Bearer ${user.user.jwt}`,
                    },
                });
                const uploadedPic = await response
                setImage("")
                setImageName("")
                setStatus({loading: false})
                console.log('success')
        } catch (e) {
            console.log(e)
            setStatus({loading: false})
        }

    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className=" w-full md:w-2/3 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Add Profile Picture
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <form className="mt-8 space-y-6" onSubmit={uploadProfilePicture}>
                                    <div className="space-y-6 px rounded-md shadow-sm">
                                        <div className='grid grid-cols-12 gap-3'>
                                            <button className='col-span-6 mr-3 inline-flex justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm'>
                                                upload profile picture
                                            </button>
                                            <input
                                                type="file"
                                                required
                                                className="cursor-pointer absolute block opacity-0 pin-r pin-t"
                                                onChange={handleFileChange}/>
                                            <div className='col-span-5'>{imageName}</div>
                                            </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 flex flex-col items-center">
                                    {status.loading && <CircularProgress></CircularProgress>}

                                        <button
                                            disabled={status.loading}
                                            type="submit"
                                            className="inline-flex justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm"
                                        >
                                            ADD PROFILE PICTURE
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
