import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { CircularProgress, Snackbar } from '@mui/material'
import { useAuth } from '../auth'
import axios from 'axios'

export default function AddProjectModal(props) {
    const { user } = useAuth()
    const cancelButtonRef = useRef(null)
    const [projectName, setProjectName] = useState('')
    const [projectDescription, setProjectDescription] = useState('')
    const [imageName, setImageName] = useState('')
    const [modelName, setModelName] = useState('')
    const [image, setImage] = useState('')
    const [model, setModel] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [status, setStatus] = useState({
        loading: false,
        error: false,
        message: '',
    })

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }
        setImage(e.target.files);
        setImageName(e.target.files[0].name)

        // 🚩 do the file upload here normally...
    };

    const onModelChange = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files;
        setModelName(file[0].name);
        setModel(file);

    }

    const createProject = async (event) => {
        event.preventDefault()

        try {
            setStatus({ loading: true, error: false, message: '' })
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/projects`,
                {
                    title: projectName,
                    description: projectDescription,
                    users_permissions_users: [
                        {
                            id: user.user.user.id
                        }
                    ]
                },
                {
                    headers: {
                        Authorization:
                            `Bearer ${user.user.jwt}`,
                    },
                });
            const project = await response;
            await uploadImage(project.data.id);
            await uploadModel(project.data.id);
            setStatus({ loading: false, error: false, message: '' })
            props.isOpen(false)
            return project;
        } catch (e) {
        }

    };

    const uploadModel = async (projectId) => {
        const formData = new FormData();
        formData.append('files', model[0], model.name)
        formData.append('ref', 'project')
        formData.append('refId', projectId)
        formData.append('field', 'model')

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData,
                {
                    headers: {
                        Authorization:
                            `Bearer ${user.user.jwt}`,
                    },
                });
            setPhone("")
            setProjectDescription("")
            setProjectName("")
            setModelName("");
            setModel("");
            setImage("")
            setImageName("")
            setModelName("")
        } catch (e) {
        }

    };

    const uploadImage = async (projectId) => {
        const formData = new FormData();
        formData.append('files', image[0], image.name)
        formData.append('ref', 'project')
        formData.append('refId', projectId)
        formData.append('field', 'image')

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData,
                {
                    headers: {
                        Authorization:
                            `Bearer ${user.user.jwt}`,
                    },
                });
        } catch (e) {
        }
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className=" w-full md:w-2/3 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Add Project
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <form className="mt-8" onSubmit={createProject}>
                                    <div className="rounded-md shadow-sm">
                                        <div className="py-6 flex justify-center">
                                            <div className='pr-4 w-full'>
                                                <input
                                                    id="project-name"
                                                    name="projectName"
                                                    type="string"
                                                    autoComplete="name"
                                                    required
                                                    className="w-full relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="Project Name"
                                                    value={projectName}
                                                    onChange={(event) => setProjectName(event.target.value)}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <input
                                                    id="phone-number"
                                                    name="phone"
                                                    type="phone"
                                                    autoComplete="phone"
                                                    required
                                                    className="w-full relative block appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="Phone Number"
                                                    value={phone}
                                                    onChange={(event) => setPhone(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='py-6'>
                                            <textarea
                                                id="project-description"
                                                name="projectDescription"
                                                autoComplete="name"
                                                required
                                                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Project Description"
                                                value={projectDescription}
                                                onChange={(event) => setProjectDescription(event.target.value)}
                                            />
                                        </div>
                                        <div className='grid grid-cols-12 gap-3'>
                                            <button className='col-span-6 mr-3 inline-flex justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm'>
                                                upload project image
                                            </button>
                                            <input
                                                type="file"
                                                required
                                                className="cursor-pointer absolute block opacity-0 pin-r pin-t"
                                                onChange={handleFileChange} />
                                            <div className='col-span-5'>{imageName}</div>
                                        </div>
                                        <p className="mb-6 text-xs italic">JPEG or PNG.</p>
                                        <div className='grid grid-cols-12 gap-3'>
                                            <button className='col-span-6 mr-3 inline-flex justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm'>
                                                upload project model
                                            </button>
                                            <input
                                                type="file"
                                                required
                                                className=" cursor-pointer absolute block opacity-0 pin-r pin-t"
                                                onChange={onModelChange} />
                                            <div className='col-span-5'>{modelName}</div>
                                        </div>
                                        <p class="mb-6 text-xs italic">Sketchup file or GLB.</p>



                                    </div>
                                    <div className="mt-5 sm:mt-6 flex flex-col items-center">
                                        {status.loading && <CircularProgress></CircularProgress>}

                                        <button
                                            disabled={status.loading}
                                            type="submit"
                                            className="inline-flex justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm"
                                        >
                                            ADD PROJECT
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
