import trashIcon from '../assets/icons/trash.svg'
import axios from 'axios'
import { useAuth } from '../auth'
import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import AddViewer from './AddViewer'
import { CircularProgress } from '@mui/material'


const classes = {
  selectionTitle: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    letterSpacing: '0.05em',
    color: '#262626',
  }
}

export default function Viewers(props) {
  const [viewers, setViewers] = useState([])
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })
  let {id} = useParams()
  const { user } = useAuth()

  const removeViewer = async (removeUser) => {
    const currentViewers = viewers.map(viewer => viewer.id)
    const newViewers = currentViewers.filter(v => v !== removeUser.id)
    const headers = {
      'Authorization': `Bearer ${user.user.jwt}`
  };
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${id}`,
        {
          users_permissions_users: newViewers
        },
        { headers });
        window.location.reload(false)
    } catch (e) {
      console.log(e)
    }
  }

  const person = props.person


  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        setStatus({ loading: true })
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/projects/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user.user.jwt}`,
            },
          }
        )
        setStatus({ loading: false })
        const projectInfo = await data
        setViewers(projectInfo.users_permissions_users)
      } catch (error) {
        let errorMessage =
          error?.response?.data?.message?.[0]?.messages?.[0]?.message
        setStatus({
          loading: false,
          isOpenSnackStatus: true,
          message: errorMessage
            ? errorMessage
            : 'An unexpected error has occurred',
        })
      }
    }
    getProjectInfo()
  }, [])

  return (
    <div >
      <div style={classes.selectionTitle} className='text-center pb-6'>Viewers</div>
      {status.loading ? 
      <CircularProgress data-cy="loadingViewers"/> :
      <table className="min-w-full divide-y divide-gray-300">
      <tbody className="divide-y divide-gray-200 bg-white">
        {viewers.map((viewer, index) =>
                  <tr key={viewer.email}
                  data-cy="viewerEmail">
                  <td className="p-6">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={viewer.profilePicture ? viewer.profilePicture.url : require('../assets/images/DefaultUserProfileImage.png')} alt="profile pic" />
                      </div>
                    </div>
                  </td>
                  <td className='p-6 text-left'>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900 text-right">{viewer.name}</div>
                    </div>
                  </td>
                  <td className='p-6 text-left'>
                    <div className="text-gray-900 text-right">{viewer.email}</div>
                  </td>
                  <td className='p-6 text-left'>
                    <div className="text-gray-900">{viewer.phone}</div>
                  </td>
                  <td className='p-6'>
                    <button 
                    onClick={() => removeViewer(viewer)}
                    data-cy="deleteViewer">
                      <img src={trashIcon} />
                    </button>
                  </td>
                </tr>
        )}

      </tbody>
    </table>
      }

      <AddViewer projectId = {id}></AddViewer>
    </div>
  )
}
