import { useAuth } from '../auth'
import React, { useState } from 'react'
import ForgotPasswordModal from './ForgotPasswordModal'
import { PencilIcon } from '@heroicons/react/24/outline'

const FormSection = () => {
    const user = useAuth()
    const [open, setOpen] = useState(false)
    const [option, setOption] = useState("")
    const [name, setName] = useState(JSON.parse(localStorage.getItem('zukeUser')).user.name || user.user.user.user.name)
    const [phone, setPhone] = useState(JSON.parse(localStorage.getItem('zukeUser')).user.phone || user.user.user.user.phone)
    const [email, setEmail] = useState(JSON.parse(localStorage.getItem('zukeUser')).user.email || user.user.user.user.email)

    const options = {
        Name: "name",
        Phone: "phone",
        Email: "email",
        Password: "password"
    }

    const isOpen = (isOpen) => {
        // setOpen(!open)
        setOpen(isOpen)
    }

    const openModal = (option) => {
        setOption(option)
        setOpen(true)
    }

    const updateOption = (option, newOption) => {
        if (option === "name") {
            setName(newOption);
        } else if (option === "email") {
            setEmail(newOption)
        } else if (option === "phone") {
            setPhone(newOption)
        }
    };

    return (
        <>
            <ForgotPasswordModal updateOption={updateOption} option={option} isOpen={isOpen} open={open} />
            <section className="p-4">
                <div className="flex flex-col p-6">
                    <div className="pl-20 text-lg font-roboto">Your Info:</div>
                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text"
                                className="h-14 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder={name}
                                disabled
                                data-cy="userName"
                            />
                            <button
                                onClick={() => openModal(options.Name)}
                                className="h-10 w-1/5 py-2 px-4 mr-2"
                                data-cy="openNameModal">
                                <PencilIcon className='h-8'></PencilIcon>
                            </button>

                        </div>
                    </div>

                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text"
                                className="h-14 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder={email}
                                disabled
                                data-cy="userEmail"
                            />
                            <button
                                onClick={() => openModal(options.Email)}
                                className="h-10 w-1/5 py-2 px-4 mr-2"
                                data-cy="openEmailModal"
                            >
                                <PencilIcon className='h-8'></PencilIcon>
                            </button>

                        </div>
                    </div>
                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text"
                                className="h-14 pr-6 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder="********" disabled
                                data-cy="userPassword"
                            />
                            <button
                                onClick={() => openModal(options.Password)}
                                className="h-10 w-1/5 py-2 px-4 mr-2"
                                data-cy="openPasswordModal"
                            >
                                <PencilIcon className='h-8'></PencilIcon>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text"
                                className="h-14 pr-6 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder={phone}
                                disabled
                                data-cy="userPhone"
                            />
                            <button
                                onClick={() => openModal(options.Phone)}
                                className="h-10 w-1/5 py-2 px-4 mr-2"
                                data-cy="openPhoneModal"
                            >
                                <PencilIcon className=' h-8'></PencilIcon>
                            </button>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FormSection