import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { useAuth } from '../auth'
import AddSeatOptions from './AddSeatOptions'

export default function AddSeatModal(props) {
  const cancelButtonRef = useRef(null)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [selection, setSelection] = useState('');
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })
  const { user } = useAuth()


  const generatePassword = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    var generatedPassword = "";

    for (var i = 0; i <= passwordLength; i++) {
      var char = Math.floor(Math.random() * chars.length + 1);
      generatedPassword += chars.charAt(char)
    }
    return generatedPassword
  }

  const createUser = async (event) => {
    event.preventDefault()
    const genPass = generatePassword()
    const username = email
    setStatus({ loading: true, error: false, message: '' })
    try {
      const newUser = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/local/register`,
        {
          phone,
          email,
          company: `${user.user.user.companies[0].name}`,
          password: genPass,
          role: 1,
          name: `${firstName} ${lastName}`,
          username,
        }
      )
      await sendPasswordReset(email)
      setStatus({ loading: false })
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      await payForSeat(selection.priceCode)

    } catch (e) {
      console.log(e)
      setStatus({ loading: false })
    }

  }

  const payForSeat = async (priceCode) => {
    // event.preventDefault()
    console.log(selection)
    const price = priceCode
    const { jwt } = user.user
    console.log(price)

    // let params = new URLSearchParams(document.location.search);
    // let price = params.get("price");

    try {
      setStatus({ loading: true })
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscriptions/addSeat`,
        {
          price,
          email: user.user.user.email
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      const data = await response
      // Handle success.
      setStatus({ loading: false })
    } catch (error) {
      const errorMessage = error?.response?.data?.message[0]?.messages[0]?.message
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: errorMessage
          ? errorMessage
          : 'An unexpected error has occurred',
      })
    }
  }

  const sendPasswordReset = async (email) => {
    console.log('send email')
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, {
      email
    })
      .then(response => {
        console.log('Your user received an email');
      })
      .catch(error => {
        console.log('An error occurred:', error.response);
      });
  }

  const onSelectionChange = (selection) => {
    setSelection(selection)
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.isOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Add Seat
                    </Dialog.Title>
                  </div>
                </div>
                <form className="mt-8 space-y-6" onSubmit={createUser}>
                <AddSeatOptions onSelectionChange={onSelectionChange}></AddSeatOptions>

                  <div className="space-y-6 px rounded-md shadow-sm">
                    <div className="flex justify-between">
                      <div className='pr-4'>
                        <input
                          id="first-name"
                          name="firstName"
                          type="string"
                          autoComplete="name"
                          required
                          className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(event) => setFirstName(event.target.value)}
                        />
                      </div>
                      <div>
                        <input
                          id="last-name"
                          name="lastName"
                          type="string"
                          autoComplete="name"
                          required
                          className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(event) => setLastName(event.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Email address"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        id="phone-number"
                        name="phone"
                        type="phone"
                        autoComplete="phone"
                        required
                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button disabled={status.loading === true}
                      type="submit"
                      className="disabled:opacity-25 inline-flex w-full justify-center border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm"
                    >
                      ADD SEAT
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
