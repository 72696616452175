const ProfileSection = () => {
    return (
      <section className="p-6" >
        <div className="flex flex-col p-6">
          <div className="pl-24 text-lg font-roboto">Designated Account Manager</div>
        </div>
        <div className="flex pl-24 items-center p-4">
        <div className="flex flex-col items-center mr-4">
          <div className="rounded-full h-16 w-16 bg-gray-400 flex items-center justify-center mb-2">
            <p className="text-white font-bold text-xl">SP</p>
          </div>
          <p className="text-roboto">Sydney Phillips</p>
        </div>
        <div className="flex items-center ml-24">
          <p className="text-roboto mr-24">sydney@zukear.com</p>
          <p className="text-roboto">913-660-3129</p>
        </div>
        </div>
      </section>
    );
  };
  export default ProfileSection