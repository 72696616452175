import { useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import Layout from './pages/Layout'
import LoginPage from './pages/LoginPage'
import SignUpEmailPage from './pages/SignUpEmailPage'
import SignUpPasswordPage from './pages/SignUpPasswordPage'
import SignUpNamePage from './pages/SingUpNamePage'
import WelcomePage from './pages/WelcomePage'
import SelectSubscriptionPage from './pages/SelectSubscriptionPage'
import SearchCompanyPage from './pages/SearchCompanyPage'
import ValidateCodePage from './pages/ValidateCodePage'
import HomePage from './pages/HomePage'
import { AuthContext, ProjectContext } from './auth'
import SignUpPhoneAndCompanyPage from './pages/SignUpPhoneAndCompanyPage'
import Project from './pages/Project'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import '@fontsource/roboto'
import ModalElement from './pages/ModalElement'
import AccountInfo from './components/AccountInfo'
import ResetPassword from './pages/ResetPassword'
import Viewers from './components/Viewers'
import Pictures from './components/Pictures'
import Videos from './components/Videos'
import AdminPage from './pages/AdminPage'
import ModelViewer from './components/ModelViewer'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'

// import { createTheme, ThemeProvider } from '@emotion/react'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(179, 16, 236, 1)',
    },
  },
})

export default function App(props) {
  const [project, setProject] = useState("")
  const [user, setUser] = useState(() => {
    try {
      const cachedUser = localStorage.getItem('zukeUser')
      return cachedUser ? { user: JSON.parse(cachedUser) } : {}
    } catch (e) {
      return {}
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ user }}>
        <ProjectContext.Provider value={project}>
          <Router>
            <Routes>
            </Routes>
            {Object.keys(user).length !== 0 ?
              <Layout>
                <Routes>
                  <Route
                    path='/'
                    element={
                      <Navigate to='/projects' />
                      // <HomePage onProjectSelect={(project) => setProject({ project: project })} />
                    }
                  />
                  <Route
                    path='/login'
                    element={<LoginPage />}
                  />
                  <Route
                    path='/projects'
                    element={<HomePage onProjectSelect={(project) => setProject({ project: project })} />}
                  />
                  <Route
                    path='/projects/:id'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <Project />
                      ) : (
                        <Navigate to='/login' replace />
                      )
                    } >
                  </Route>
                  <Route path='/projects/:id/viewers'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <Viewers />
                      ) : (
                        <Navigate to='/login' replace />
                      )
                    } />
                  <Route path='/projects/:id/pictures'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <Pictures />
                      ) : (
                        <Navigate to='/login' replace />
                      )
                    } />
                  <Route path='/projects/:id/videos'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <Videos />
                      ) : (
                        <Navigate to='/login' replace />
                      )
                    } />
                  <Route path='/projects/:id/tour'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <ModelViewer />
                      ) : (
                        <Navigate to='/login' replace />
                      )
                    } />
                  <Route
                    path='/projects/:id/:type/:element'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <ModalElement />
                      ) : (
                        <LoginPage onLogin={(user) => setUser({ user: user })} />
                      )
                    }
                  />
                  <Route path='account'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <AccountInfo />
                      ) : (
                        <LoginPage onLogin={(user) => setUser({ user: user })} />
                      )
                    } />
                  <Route path='admin'
                    element={
                      Object.keys(user).length !== 0 ? (
                        <AdminPage />
                      ) : (
                        <LoginPage onLogin={(user) => setUser({ user: user })} />
                      )
                    } />
                  <Route
                    exact
                    path='/reset-password'
                    element={
                      <ResetPassword />
                    }
                  />
                  <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                  <Route
                    path='/select-subscription-model'
                    element={<SelectSubscriptionPage />}
                  />
                </Routes>
              </Layout> :
              <Routes>
                <Route path='account'
                  element={
                    Object.keys(user).length !== 0 ? (
                      <AccountInfo />
                    ) : (
                      <LoginPage onLogin={(user) => setUser({ user: user })} />
                    )
                  } />
                <Route
                  exact
                  path='/reset-password'
                  element={
                    <ResetPassword />
                  }
                />
                <Route
                  path='/'
                  element={
                    <LoginPage onLogin={(user) => setUser({ user: user })} />
                  }
                />
                  <Route
                    path='/login'
                    element={<LoginPage />}
                  />
                <Route
                  path='/projects/:id'
                  element={
                    Object.keys(user).length !== 0 ? (
                      <Project />
                    ) : (
                      <Navigate to='/login' replace />
                    )
                  }
                />
                <Route
                  exact
                  path='/welcome'
                  element={
                    Object.keys(user).length !== 0 ? (
                      props.history.push("/login")
                    ) : (
                      <WelcomePage />
                    )
                  }
                />
                <Route
                  path='/sign-up-name'
                  element={
                    Object.keys(user).length !== 0 ? (
                      <Navigate to='/login' replace />
                    ) : (
                      <SignUpNamePage />
                    )
                  }
                />
                <Route
                  path='/sign-up-phone-and-company'
                  element={
                    Object.keys(user).length !== 0 ? (
                      <Navigate to='/login' replace />
                    ) : (
                      <SignUpPhoneAndCompanyPage />
                    )
                  }
                />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/sign-up-email' element={<SignUpEmailPage />} />
                <Route
                  path='/sign-up-password'
                  element={
                    <SignUpPasswordPage
                      onSignup={(user) => setUser({ user: user })}
                    />
                  }
                />
                <Route
                  path='/select-subscription-model'
                  element={<SelectSubscriptionPage />}
                />
                <Route path='/search-company' element={<SearchCompanyPage />} />
                <Route path='/validate-code' element={<ValidateCodePage />} />
                {/* <Route
                  path='/'
                  element={
                    Object.keys(user).length !== 0 ? (
                      <HomePage />
                    ) : (
                      <Navigate to='/login' replace />
                    )
                  }
                /> */}
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>
            }
          </Router>
        </ProjectContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider >
  )
}
