import { createContext, useContext } from 'react'

export const AuthContext = createContext({ user: {} })

export const ProjectContext = createContext({projectName: ""})

export const logOut = () => {
  localStorage.removeItem('zukeUser')
  window.location.reload()
}

export function useAuth() {
  return useContext(AuthContext)
}

export function useProject() {
  return useContext(ProjectContext)
}
