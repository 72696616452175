import { useState } from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import { useAuth } from '../auth';

const Navbar = () => {
  const user = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const adminPage = async () => {
    navigate('/admin')
  }

  const accountPage = async () => {
    navigate('/account')
  }
  return (
    <nav className="flex justify-center items-center bg-white p-2">
      {user.user.user.user.role.id === 1 &&
        <a href='https://billing.stripe.com/p/login/8wM5mZcvacyF3decMM' 
        className={`text-lg font-roboto mx-2 hover:border-b-2 hover: border-zuke-blue transition-colors`}
        >Payment Info</a>}
      <button onClick={() => accountPage()} 
      className={`text-lg font-roboto mx-2 hover:border-b-2 hover: border-zuke-blue transition-colors ${location.pathname === '/account' ? 'border-b-2 border-zuke-blue' : 'border-b-0'}`}
      >
        Account Info</button>
      <button 
      onClick={() => adminPage()} 
      className={`text-lg font-roboto mx-2 hover:border-b-2 hover: border-zuke-blue transition-colors ${location.pathname === '/admin' ? 'border-b-2 border-zuke-blue' : 'border-b-0'}`}
      data-cy="admin"
      >
        Admin Info</button>
    </nav>
  );
};

  export default Navbar;