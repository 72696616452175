import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'
import ProfileSection from './ProfileSection'
import FormSection from './FormSection'


export default function AccountInfo() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  console.log()
  return (
    <>
    <Navbar></Navbar>
    <ProfileSection></ProfileSection>
    <FormSection></FormSection>
      </>
  )
}
