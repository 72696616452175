import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link, useNavigate, useParams, useLocation, redirect } from 'react-router-dom'
import { useAuth, logOut } from '../auth'
import Modal from 'react-modal';
import AddProfilePicture from '../components/AddProfilePictureModal';

import {
  Bars3Icon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Sidebar(props) {
  const location = useLocation()
  const urlParts = location.pathname.split('/')
  const projectId = urlParts[2]
  const [open, setOpen] = useState(false)

  const isOpen = (isOpen) => {
    setOpen(isOpen)
  }

  const navigate = useNavigate()
  const { user } = useAuth()
  console.log(user)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [activeId, setActiveId] = useState();

  const navigation = [
    { name: 'projects', path: '/projects', icon: FolderIcon, current: false },
    { name: 'pictures', path: `/projects/${projectId}/pictures`, icon: FolderIcon, current: false },
    { name: 'videos', path: `/projects/${projectId}/videos`, icon: FolderIcon, current: false },
    // { name: 'Tours', href: '#', icon: FolderIcon, current: false },
    { name: 'view project', path: `/projects/${projectId}/tour`, icon: FolderIcon, current: false },
    { name: 'viewers', path: `/projects/${projectId}/viewers`, icon: FolderIcon, current: false },
  ]

  return (
    <>
      <AddProfilePicture isOpen={isOpen} open={open} />
      <div className={`${location.pathname.includes('tour') ? "hidden" : "flex flex-1 flex-col lg:pl-64 fixed top-0 left-0"}`}>
        <div className="z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>


      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden border-r border-gray-200" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-white bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white border-r border-gray-200">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-black" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src={require('../assets/images/zukeLogo.png')}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      <ul>
                        {navigation.map((item, index) => (
                          <Link to={item.path}
                            key={item.name}

                          >
                            <li onClick={item.action}
                              key={item.name}
                              className={classNames(
                                item.current
                                  ? 'font-roboto bg-zuke-blue'
                                  : 'font-roboto text-black hover:bg-zuke-blue hover:text-black',
                                'group flex items-center rounded-md px-2 py-2 text-base font-medium text-lg'
                              )}>
                              <span key={item.name}>{item.name}</span>
                            </li>
                          </Link>
                        ))}
                      </ul>
                      <ul>
                        {user.user.user.role.type === "viewer" &&
                          <Link className='pl-4' to={{
                            pathname: '/select-subscription-model',
                            state: { ...location.state }
                          }}>
                            <li
                              key="upgrade"
                              className='font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium'
                            >
                              Upgrade
                            </li>
                          </Link>
                        }
                        <Link className='pl-4' to={{
                          pathname: 'account',
                          state: { ...location.state }
                        }}>
                          <li
                            key="settings"
                            className='font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium'
                          >
                            Settings
                          </li>
                        </Link>
                        <li onClick={logOut}
                          key='logout'
                          className='font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium hover:cursor-pointer'
                        >
                          Logout
                        </li>
                        <li>
                          <a href='mailto:hello@zukear.com' className='font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium'>Help</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 border-t border-gray-200 p-4 bg-zuke-red">
                    <a href="#" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-10 w-10 rounded-full"
                            src={user.user.user.profilePicture ? user.user.user.profilePicture.url : require('../assets/images/DefaultUserProfileImage.png')}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-black group-hover:text-gray-900\">{user.user.user.name}</p>
                          <p className="text-sm font-medium text-black group-hover:text-gray-700">View profile</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className={`${location.pathname.includes('tour') ? "hidden lg:hidden" : "hidden h-screen lg:inset-y-0 lg:flex lg:w-64 lg:flex-col sticky top-0"}`}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <a href='/projects'>
                  <img
                    className="pl-8 h-32 w-auto"
                    src={require('../assets/images/zukeLogo.png')} alt="profile pic"
                  />
                </a>

              </div>
              <ul>
                {navigation.map((item, index) =>
                  <>
                    {item.name === 'Settings' || item.name === 'Help' || item.name === 'Logout' || location.pathname.includes("/projects/") ?
                      <Link className='pl-4' to={{
                        pathname: item.path,
                        state: { ...location.state }
                      }}
                      key={item.name}
                      data-cy={item.name}>
                        <li onClick={() => setActiveId(index)}
                          key={item.name}
                          className={classNames(
                            `pl-20 font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium ${location.pathname.includes(item.name) && item.name !== 'projects' ? 'bg-zuke-blue' : ''}`
                          )}
                        >
                          {item.name}
                        </li>
                      </Link> : null
                    }
                  </>
                )}

              </ul>
            </div>
            <ul>
              {user.user.user.role.type === "viewer" &&
                <Link className='pl-4' to={{
                  pathname: '/select-subscription-model',
                  state: { ...location.state }
                }}>
                  <li
                    className='pl-20 font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium'
                  >
                    Upgrade
                  </li>
                </Link>
              }
              <Link className='pl-4' to={{
                pathname: 'account',
                state: { ...location.state }
              }}>
                <li
                  className='pl-20 font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium'
                  data-cy="settings"

                >
                  Settings
                </li>
              </Link>
              <li 
                onClick={logOut}
                className='pl-20 font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium hover:cursor-pointer'
                data-cy="logout"
              >
                Logout
              </li>
              <li>
                <a href='mailto:hello@zukear.com' className='pl-20 font-roboto flex flex-1 text-black hover:bg-zuke-blue hover:text-black group flex items-center rounded-md px-2 py-2 text-lg font-medium'>Help</a>
              </li>
            </ul>
            <div className="flex flex-shrink-0 bg-zuke-red p-4">
              <button onClick={() => setOpen(true)} className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={user.user.user.profilePicture ? user.user.user.profilePicture.url : require('../assets/images/DefaultUserProfileImage.png')}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{user.user.user.name}</p>
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">View profile</p>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}