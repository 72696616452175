import React, { useState, useEffect } from 'react'
import { Modal } from '@mui/material'
import { Grid } from '@mui/material'
import CloseIcon from '../../assets/icons/CloseIcon.svg'
import { CircularProgress } from "@mui/material";
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../auth'
import ReactPlayer from 'react-player'
import '@fontsource/roboto'

const classes = {
    root: {
        flexGrow: 1,
        textAlign: 'center',
        paddingTop: '50hv',
        minWidth: '350px',
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
    },
    selectionDescription: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '18px',
        lineHeight: '21px',
        textAlign: 'center',
        letterSpacing: '0.05em',
        color: '#262626',
        marginBottom: '40px'
    },
    
    contentModalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'scroll',
    },
    showContentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeGrid: {
        display: 'flex',
        justifyContent: 'right',
        paddingRight: '20px',
        paddingBottom: '25px',
        background: 'white'
    },
    px: {
        width: '.1%',
        backgroundColor: 'white',
    },
    closeIcon: {
        cursor: 'pointer'
    },
    emptyMessage: {
        marginTop: '10vh',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '22px',
        lineHeight: '21px',
        letterSpacing: '0.05em',
        color: '#000000',
        paddingBottom: '60px',
        textOverflow: 'ellipsis',
        transform: 'translate(0px, -250px)'
    },
    contentModalMainContainer: {
        height: '80vh',
        width: '80vw'
    },
    contentModalTitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '50px',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '28px',
        lineHeight: '21px',
        letterSpacing: '0.05em',
        color: '#262626',
        overflowX: 'clip'
    },
    contentModalImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '60vh',
    }
}

export default function ModalElement(props) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { id, type, element } = useParams();

    const [isOpenContentModal, setIsOpenContentModal] = useState(true);
    const [selectedOption, setSelectedOption] = useState('Pictures');
    const [projectInfo, setProjectInfo] = useState([]);
    const [arrayPhotos, setArrayPhotos] = useState([]);
    const [arrayVideos, setArrayVideos] = useState([]);
    const [arrayData, setArrayData] = useState([]);
    const photosLen = arrayPhotos.length;
    const [elementIndex, setElementIndex] = useState(0);
    const [contentUrl, setContentUrl] = useState('');
    const [status, setStatus] = useState({
        loading: false,
        isOpenSnackStatus: false,
        message: '',
    });


    const handleCloseContentModal = () => {
        navigate(`/projects/${id}`);
    }

    const getName = (array, id) => {
        let nameComplete = array[id].name;
        let name = nameComplete.split('.')[0];
        return name;
    }

    const getProjectInfo = async () => {
        try {
            setStatus({ loading: true });
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/projects/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.user.jwt}`,
                    },
                }
            );
            setStatus({ loading: false });
            projectInfo = await data;
            setArrayPhotos(projectInfo.photoTour);
            setArrayVideos(projectInfo.videoTour);
            setProjectInfo(projectInfo);

            if (type === 'videos') {
                const position = projectInfo.videoTour.map(element => String(element.id)).indexOf(element);
                setElementIndex(position);
                setArrayData(projectInfo.videoTour);
                setSelectedOption('Videos');
                let url = projectInfo.videoTour[position].url
                setContentUrl(url)
            }
            if (type === 'pictures') {
                const position = projectInfo.photoTour.map(element => String(element.id)).indexOf(element);
                setElementIndex(position);
                setSelectedOption('Pictures');
                setArrayData(projectInfo.photoTour);
            }
        } catch (error) {
            let errorMessage = error?.response?.data?.message?.[0]?.messages?.[0]?.message;
            setStatus({ loading: false, isOpenSnackStatus: true, message: errorMessage ? errorMessage : 'An unexpected error has occurred' });
        }
    }


    useEffect(() => {
        getProjectInfo();
    }, []);

    return (
        <div style={classes.root} style={{ width: '100vw', height: '100vh' }}>
            {
                status.loading
                    ?
                    <div>
                        <Grid
                            container
                            spacing={3}
                            paddingLeft='26px'
                            paddingRight={'26px'}
                            justifyContent={'center'}
                            style={{ paddingTop: '30vh' }}
                        >
                            <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </div>
                    :
                    <Modal
                        open={isOpenContentModal}
                        onClose={handleCloseContentModal}
                        style={classes.contentModalContainer}
                    >
                        <Grid
                            container
                            spacing={3}
                            justifyContent={'center'}
                            display='flex'
                            margin='20px'
                            backgroundColor='white'
                            style={{ width: '100vw', height: '95vh' }}
                        >
                            <Grid
                                item
                                xs={12} sm={12} md={12} ls={12} xl={12}
                                style={classes.closeGrid}
                            >
                                <CloseIcon onClick={() => handleCloseContentModal()} style={classes.closeIcon} />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={12} md={12} ls={12} xl={12}
                                style={classes.showContentContainer}
                                paddingLeft={selectedOption === 'Videos' ? '5%' : '0px'}
                            >
                                <div>
                                    {
                                        elementIndex < 0
                                            ?
                                            <div style={classes.emptyMessage}>
                                                <img src={require('../../assets/images/not-found.png')}></img>
                                                Content not found
                                            </div>
                                            :
                                            selectedOption === 'Pictures'
                                                ?
                                                <div style={classes.contentModalMainContainer}>
                                                    <div style={classes.contentModalTitleContainer}>
                                                        {arrayPhotos[elementIndex] && arrayPhotos[elementIndex].name
                                                            ?
                                                            getName(
                                                                arrayPhotos, elementIndex
                                                            )
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                    <div style={classes.contentModalImageContainer}>
                                                        <img
                                                            src={arrayPhotos?.[elementIndex]?.url}
                                                            style={{
                                                                objectFit: 'contain',
                                                            }}
                                                            key={elementIndex}
                                                        >
                                                        </img>
                                                    </div>
                                                </div>
                                                :
                                                selectedOption === 'Videos'
                                                    ?
                                                    <ReactPlayer url={contentUrl}
                                                        controls={true}
                                                        playing={true}
                                                        height='100%'
                                                        width='95%'
                                                    />
                                                    :
                                                    null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Modal>
            }
        </div>
    );
}