import React from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import CloseIcon from '../../assets/icons/CloseIcon.svg'
import ZukuFillButton from '../../components/common/ZukuFillButton'
import ZukuTextField from '../../components/common/ZukuTextfield'

export default function SearchCompanyPage() {
  const onClickNextButton = () => {}

  return (
    <div className='sign-up-email-page-container'>
      <div className='sign-up-email-page-sign-up-email-page'>
        <p className='sign-up-email-page-please-enter-your-em'>
          Please Search for Your Company
        </p>
        <div className='search-company-page-back-button-container'>
          <button className='zuku-fill-btn-back'>Back</button>
        </div>
        <div className='seach-company-page-next-button-container'>
          <ZukuFillButton text='Next' onClick={onClickNextButton} />
        </div>
        <div className='search-company-page-text-field-container'>
          <ZukuTextField
            type='text'
            placeholder='Search...'
            className='seach-company-text-left '
          />
        </div>

        <div className='suku-icon-container'>
        <img src={zukeIcon} alt="SVG logo image"/>
        </div>
        <div className='close-button-close-button'>
          <CloseIcon />
        </div>
      </div>
    </div>
  )
}
