import React from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import CloseIcon from '../../assets/icons/CloseIcon.svg'
import ZukuFillButton from '../../components/common/ZukuFillButton'
import ZukuTextField from '../../components/common/ZukuTextfield'
import './validate-code-page.css'

export default function ValidateCodePage() {
  const onClickNextButton = () => {}

  return (
    <div className='sign-up-email-page-container'>
      <div className='sign-up-email-page-sign-up-email-page'>
        <p className='validate-comde-page-text'>
          An email has been sent to the administrator within the requested
          organization with a code. Once they send you the code, enter it below.{' '}
        </p>
        <div className='search-company-page-back-button-container'>
          <button className='zuku-fill-btn-back'>Back</button>
        </div>
        <div className='seach-company-page-next-button-container'>
          <ZukuFillButton text='Next' onClick={onClickNextButton} />
        </div>
        <div className='validate-code-page-fields-container'>
          <div className='validate-code-page-text-field-container'>
            <ZukuTextField type='text' />
          </div>
          <div className='validate-code-page-text-field-container'>
            <ZukuTextField type='text' />
          </div>
          <div className='validate-code-page-text-field-container'>
            <ZukuTextField type='text' />
          </div>
          <div className='validate-code-page-text-field-container'>
            <ZukuTextField type='text' />
          </div>
          <div className='validate-code-page-text-field-container'>
            <ZukuTextField type='text' />
          </div>
          <div className='validate-code-page-text-field-container'>
            <ZukuTextField type='text' />
          </div>
        </div>

        <div className='suku-icon-container'>
          <img src={zukeIcon} alt="SVG logo image"/>
        </div>
        <div className='close-button-close-button'>
          <CloseIcon />
        </div>
      </div>
    </div>
  )
}
