export default function PrivacyPolicy() {
    return (
        <div>
                <p>


Privacy Policy
Controller
Zukurri, LLC
Contact Person: Sydney Phillips sydney@zukurri.com & hello@zukear.com
Name of the register
Introduction
Zukurri, LLC (“Zukurri” or “we”), collects personal data of its customers and visitors of its 
websites and applications whilst providing its services. To use the service, you are required to 
register to Zukurri by providing your real name or alias and your email address. Some functions 
and sub-services of the site may require more information to be provided, but this will be clearly 
stated and you will be requested to provide the extra information before you can use these certain
services and functions.
This Privacy Policy governs the principles of storing and processing the personal data included 
in the Register. This Privacy Policy explains what data we process, how we do that and how you 
may use your rights as a data subject (e.g. right to object, right of access).
This Privacy Policy constitutes a description of the data file of the Register as set out in the 
Finnish Personal Data Act (Sections 10 and 24).
This Privacy Policy may be updated from time to time to if required in order to reflect the 
changes in data processing practices or otherwise. You can find the current version on our 
website.
The Privacy Policy does not address and we are not responsible for, the privacy practices of any 
third parties.
Interpretation and Definitions
Interpretation
The words of which the initial letter is capitalized have meanings defined under the following 
conditions. The following definitions shall have the same meaning regardless of whether they 
appear in singular or in plural.
Definitions
For the purposes of this Privacy Policy:
Account means a unique account created for You to access our Service or parts of our 
Service.
Affiliate means an entity that controls, is controlled by or is under common control with 
a party, where "control" means ownership of 50% or more of the shares, equity interest or
other securities entitled to vote for election of directors or other managing authority.
Application refers to Zuke , the software program provided by the Company.
Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) 
refers to Zukurri, LLC, 3600 S Lamar Blvd, Unit 107, Austin, TX, 78704.
Country refers to: Texas, United States
Device means any device that can access the Service such as a computer, a cellphone or a
digital tablet.
Personal Data is any information that relates to an identified or identifiable individual.
Service refers to the Application.
Service Provider means any natural or legal person who processes the data on behalf of 
the Company. It refers to third-party companies or individuals employed by the Company
to facilitate the Service, to provide the Service on behalf of the Company, to perform 
services related to the Service or to assist the Company in analyzing how the Service is 
used.
Usage Data refers to data collected automatically, either generated by the use of the 
Service or from the Service infrastructure itself (for example, the duration of a page 
visit).
You means the individual accessing or using the Service, or the company, or other legal 
entity on behalf of which such individual is accessing or using the Service, as applicable
Meta Quest & Other Applications 
Zukurri abides by and enforces the Code of conduct for Virtual Experiences set forth by Meta 
Quest and any other applications, or games. Don’t do or promote anything that is designed to 
deceive other users, Meta, developers, or that otherwise abuse our products or services, such as:
Pretending to be another person or entity, stealing someone’s identity, or creating 
or using fake accounts (If role-playing or parodying, make sure it’s clear to 
others)
Engaging in fraud, scams, or other deceptive activities
Gathering or sharing sensitive personal information, doxing others, compromising
user accounts, sharing account login information, engaging in unauthorized 
access, or sharing malware
Creating or using a Meta account if you’re under the age of 13
Don’t do or promote anything that’s illegal, abusive, or could lead to physical harm, such 
as:
Sexualizing, exploiting, or abusing minors
Bullying, harassing, stalking, or hateful behavior
Advocating, engaging in or promoting violence, human exploitation, human 
trafficking, or human smuggling. Supporting or representing groups or individuals
involved in terrorism, hate-based organizations, or criminal groups
Promoting or coordinating acts of physical harm, such as sexual or physical 
assault, or suicide or self-harm
Any form of non-consensual intimate activity, including sharing intimate images 
of others without consent
Violating intellectual property rights, such as improperly using copyrighted or 
trademarked materials
Unlawfully selling, exchanging, or promoting, regulated goods
Public spaces like open lobbies, multiplayer games, and public events create special 
opportunities for broad audiences. Please be especially mindful of your behavior and content in 
public experiences and spaces, as not all speech and behavior that might be acceptable in a 
closed experience is acceptable in a public experience. Also, do not take advantage of the unique 
characteristics of public experiences by sharing content that others do not wish to see. 
Developers (including Meta) may take action on users or experiences if these behaviors take 
place in public experiences, given their unique characteristics. Do not:
Spam others (repeatedly offer commercial services, goods, or requests) or promote 
regulated goods or services
Engage in or share sexually explicit or excessively violent behavior or conten
The full list of the Code of Conduct can be found linked here. 
Collecting and Using Your Personal Data
Types of Data Collected
Personal Data
While using Our Service, We may ask You to provide Us with certain personally identifiable 
information that can be used to contact or identify You. Personally identifiable information may 
include, but is not limited to:
Email address
First name and last name
Phone number
Address, State, Province, ZIP/Postal code, City
Usage Data
Usage Data
Usage Data is collected automatically when using the Service.
Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP 
address), browser type, browser version, the pages of our Service that You visit, the time and 
date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic 
data.
When You access the Service by or through a mobile device, We may collect certain information
automatically, including, but not limited to, the type of mobile device You use, Your mobile 
device unique ID, the IP address of Your mobile device, Your mobile operating system, the type 
of mobile Internet browser You use, unique device identifiers and other diagnostic data.
We may also collect information that Your browser sends whenever You visit our Service or 
when You access the Service by or through a mobile device.
Information Collected while Using the Application
While using Our Application, in order to provide features of Our Application, We may collect, 
with Your prior permission:
Pictures and other information from your Device's camera and photo library
We use this information to provide features of Our Service, to improve and customize Our 
Service. The information may be uploaded to the Company's servers and/or a Service Provider's 
server or it may be simply stored on Your device.
You can enable or disable access to this information at any time, through Your Device settings.
Use of Your Personal Data
The Company may use Personal Data for the following purposes:
To provide and maintain our Service, including to monitor the usage of our Service.
To manage Your Account: to manage Your registration as a user of the Service. The 
Personal Data You provide can give You access to different functionalities of the Service 
that are available to You as a registered user.
For the performance of a contract: the development, compliance and undertaking of 
the purchase contract for the products, items or services You have purchased or of any 
other contract with Us through the Service.
To contact You: To contact You by email, telephone calls, SMS, or other equivalent 
forms of electronic communication, such as a mobile application's push notifications 
regarding updates or informative communications related to the functionalities, products 
or contracted services, including the security updates, when necessary or reasonable for 
their implementation.
To provide You with news, special offers and general information about other goods, 
services and events which we offer that are similar to those that you have already 
purchased or enquired about unless You have opted not to receive such information.
To manage Your requests: To attend and manage Your requests to Us.
For business transfers: We may use Your information to evaluate or conduct a merger, 
divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or 
all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or 
similar proceeding, in which Personal Data held by Us about our Service users is among 
the assets transferred.
For other purposes: We may use Your information for other purposes, such as data 
analysis, identifying usage trends, determining the effectiveness of our promotional 
campaigns and to evaluate and improve our Service, products, services, marketing and 
your experience.
We may share Your personal information in the following situations:
With Service Providers: We may share Your personal information with Service 
Providers to monitor and analyze the use of our Service, to contact You.
For business transfers: We may share or transfer Your personal information in 
connection with, or during negotiations of, any merger, sale of Company assets, 
financing, or acquisition of all or a portion of Our business to another company.
With Affiliates: We may share Your information with Our affiliates, in which case we 
will require those affiliates to honor this Privacy Policy. Affiliates include Our parent 
company and any other subsidiaries, joint venture partners or other companies that We 
control or that are under common control with Us.
With business partners: We may share Your information with Our business partners to 
offer You certain products, services or promotions.
With other users: when You share personal information or otherwise interact in the 
public areas with other users, such information may be viewed by all users and may be 
publicly distributed outside.
With Your consent: We may disclose Your personal information for any other purpose 
with Your consent.
Retention of Your Personal Data
The Company will retain Your Personal Data only for as long as is necessary for the purposes set
out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to 
comply with our legal obligations (for example, if we are required to retain your data to comply 
with applicable laws), resolve disputes, and enforce our legal agreements and policies.
The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
retained for a shorter period of time, except when this data is used to strengthen the security or to
improve the functionality of Our Service, or We are legally obligated to retain this data for 
longer time periods.
Transfer of Your Personal Data
Your information, including Personal Data, is processed at the Company's operating offices and 
in any other places where the parties involved in the processing are located. It means that this 
information may be transferred to — and maintained on — computers located outside of Your 
state, province, country or other governmental jurisdiction where the data protection laws may 
differ than those from Your jurisdiction.
Your consent to this Privacy Policy followed by Your submission of such information represents 
Your agreement to that transfer.
The Company will take all steps reasonably necessary to ensure that Your data is treated securely
and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place 
to an organization or a country unless there are adequate controls in place including the security 
of Your data and other personal information.
Purpose for processing personal data
There are several purposes for the processing of your personal data by Zukurri:
To provide our services
Zukurri processes your personal data in the first place to be able to deliver our services to you 
and to run, maintain and develop our businesses. We would like to know who you are, so we can 
tailor our service to meet your needs and desires as precisely as possible. Some of the functions 
and services on this site are simply unable to work properly if we do not know who is using 
them. If you contact our customer service, we will use the information provided by you to 
answer your questions or solve your complaint. In order to ensure that our services and products 
are in line with your needs, these data can be used for things like customer satisfaction surveys. 
We might also request another party to do this for us.
For customer communication (including marketing)
Zukurri processes your personal data to contact you regarding our services and to inform you of 
changes to our services. Your data are also used for research and analysis to improve our 
services and our websites. We may also use your data entered on our websites in certain cases to 
send information and marketing communication by e-mail, provided that you have given us 
consent to that or other legitimate grounds for such marketing communication exists.
Legitimate grounds for processing and storage limitation
Zukurri processes your personal data to perform our contractual obligations towards you and to 
comply with legal obligations. Furthermore, we process your personal data to pursue our 
legitimate interest to maintain and develop our businesses. We store personal data only as long as
necessary for the purposes set out in this Privacy Policy. Once the data is no longer necessary, 
we delete or anonymize it as soon as possible.
Processed Data and Sources of Data
Zukurri collects two types of information from our customers: (i) Customer Data; and (ii) 
Technical Data. Although we do not normally use Technical Data to identify you as an 
individual, you can sometimes be recognized from it. In such situations, Technical Data can also 
be considered personal data under applicable laws.
We may collect the following basic Customer Data and store it in the Register: (i) first and last 
name; (ii) e-mail address, telephone number, address; (iii) payment information and transactional
data. In addition, we may collect the following data arising from the customer relationship (i) 
information relating to maintaining the customer relationship; (ii) information on marketing 
permissions; and (iii) customer service data.
We may collect Customer Data from our customers and visitors in a variety of ways, including, 
when they register to our service, when they submit customer feedback, claims or other 
communication or when they subscribe to a newsletter or fill out a form. Further, please note that
we also collect details of any transactions and payments you carry out through our service. We 
will collect Customer Data from customers and visitors only if they voluntarily submit such 
information to us or carry out transactions or payments through our services or sites. We may 
also update and supplement personal data with information provided by third parties in 
accordance with applicable data protection laws.
We and/or our authorized third party service providers, may automatically collect Technical Data
when you visit or interact with our service or site. Technical Data may include the browser name,
the type of computer or device, time spent on website, interaction with the services, URL of the 
website you visited before and after visiting the Services, the time and date of user visits, surfing 
habits, IP address, operating system and the Internet service providers utilized and other similar 
technical information.
Delete Your Personal Data
You have the right to delete or request that We assist in deleting the Personal Data that We have 
collected about You.
Our Service may give You the ability to delete certain information about You from within the 
Service.
You may update, amend, or delete Your information at any time by signing in to Your Account, 
if you have one, and visiting the account settings section that allows you to manage Your 
personal information. You may also contact Us to request access to, correct, or delete any 
personal information that You have provided to Us.
Please note, however, that We may need to retain certain information when we have a legal 
obligation or lawful basis to do so.
Disclosure of Your Personal Data
Business Transactions
If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be 
transferred. We will provide notice before Your Personal Data is transferred and becomes subject
to a different Privacy Policy.
Law enforcement
Under certain circumstances, the Company may be required to disclose Your Personal Data if 
required to do so by law or in response to valid requests by public authorities (e.g. a court or a 
government agency).
Other legal requirements
The Company may disclose Your Personal Data in the good faith belief that such action is 
necessary to:
Comply with a legal obligation
Protect and defend the rights or property of the Company
Prevent or investigate possible wrongdoing in connection with the Service
Protect the personal safety of Users of the Service or the public
Protect against legal liability
Security of Your Personal Data
The security of Your Personal Data is important to Us, but remember that no method of 
transmission over the Internet, or method of electronic storage is 100% secure. While We strive 
to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its 
absolute security.
Children's Privacy
Our Service does not address anyone under the age of 13. We do not knowingly collect 
personally identifiable information from anyone under the age of 13. If You are a parent or 
guardian and You are aware that Your child has provided Us with Personal Data, please contact 
Us. If We become aware that We have collected Personal Data from anyone under the age of 13 
without verification of parental consent, We take steps to remove that information from Our 
servers.
If We need to rely on consent as a legal basis for processing Your information and Your country 
requires consent from a parent, We may require Your parent's consent before We collect and use 
that information.
Links to Other Websites
Our Service may contain links to other websites that are not operated by Us. If You click on a 
third party link, You will be directed to that third party's site. We strongly advise You to review 
the Privacy Policy of every site You visit.
We have no control over and assume no responsibility for the content, privacy policies or 
practices of any third party sites or services.
Changes to this Privacy Policy
We may update Our Privacy Policy from time to time. We will notify You of any changes by 
posting the new Privacy Policy on this page.
We will let You know via email and/or a prominent notice on Our Service, prior to the change 
becoming effective and update the "Last updated" date at the top of this Privacy Policy.
You are advised to review this Privacy Policy periodically for any changes. Changes to this 
Privacy Policy are effective when they are posted on this page.
Your Rights
Right to access
Zukurri offers you access to the personal data we process. This means you can contact us asking 
us to inform you about your personal data that we have collected and processed and the purposes
such data are used for. We may charge a processing fee in case less than 12 months have passed 
since your last data request.
Right to correct
You have the right to have incorrect/unprecise, incomplete, outdated, or unnecessary personal 
data we have stored about you corrected or completed by contacting us.
Right to deletion
You may also ask us to delete your personal data from our systems. We will comply with such 
request unless we have a legitimate ground to not delete the data. After the data have been 
deleted, we may not immediately be able to delete all residual copies from our active servers and 
backup systems.
Right to object
You may object to certain use of your personal data if such data are processed for other purposes 
than necessary for the performance of our services or for compliance with a legal obligation. You
may also object any further processing of your personal data after prior given consent. If you 
object to the further processing of your personal data, this may lead to fewer possibilities to use 
our websites and other services.
You have the right to opt out of receiving electronic direct marketing communications from us 
by clicking on the opt-out link provided in all marketing communications we send you, and 
choosing not to receive marketing communications from us in the future. You also have the right
to prohibit us from using your personal data for direct marketing purposes, market research and 
profiling by contacting us on the addresses indicated above.
Right to restriction of processing
You may request us to restrict certain processing of your personal data. If you restrict certain 
processing of your personal data, this may lead to fewer possibilities to use our websites and 
other services.
Right to data portability
You have the right to receive your personal data from us in a structured, commonly used format 
in order to transmit the data to another controller.
How to use your rights
You may use these rights by sending a letter or e-mail, including your name, address, phone 
number and a copy of a valid ID to us on the addresses set out above. If your request regards 
personal data in a cookie, you have to enclose a copy of the said cookie. We may request the 
provision of additional information necessary to confirm your identity. We may reject requests 
that are unreasonably repetitive, excessive or manifestly unfounded. In case you consider our 
processing activities of your personal data to be inconsistent with the applicable data protection 
laws, you may lodge a complaint with the local supervisory authority for data protection.
Information Security
We will take all reasonable, appropriate security measures to protect Zukurri and our customers 
from unauthorised access to or unauthorised alteration, disclosure or destruction of personal data 
we hold. Measures include, where appropriate, encryption, firewalls, secure facilities and access 
rights systems. Should despite of the security measures, a security breach occur that is likely to 
have negative effects to your privacy, we will inform you about the breach as soon as reasonably 
possible.
Contact Us
If you have any questions about this Privacy Policy, You can contact us:
By email: hello@zukear.com
                </p>
        </div>
    )
}


