import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useAuth } from '../auth'
import AddSeatModal from './AddSeatModal'

export default function Seats() {
    const { user } = useAuth()
    const [seats, setSeats ] = useState([])
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState({
        loading: false,
        isOpenSnackStatus: false,
        message: '',
      })
      const isOpen = (isOpen) => {
        // setOpen(!open)
        setOpen(isOpen)
      }

    useEffect(() => {
        const getSeats = async () => {
          try {
            console.log(user.user)
            setStatus({ loading: true })
            const { data } = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/companies/${user.user.user.companies[0].id}`,
              {
                headers: {
                  Authorization: `Bearer ${user.user.jwt}`,
                },
              }
            )
            setStatus({ loading: false })
            const company = await data
            setSeats(company.users_permissions_users)
          } catch (error) {
            console.log(error)
            let errorMessage =
              error?.response?.data?.message?.[0]?.messages?.[0]?.message
            setStatus({
              loading: false,
              isOpenSnackStatus: true,
              message: errorMessage
                ? errorMessage
                : 'An unexpected error has occurred',
            })
          }
        }
        getSeats()
      }, [])

    return (
      <>
      <AddSeatModal isOpen={isOpen} open={open}/>
      <div className="flex flex-col pt-6">
                <div className="pl-20 text-lg font-roboto">Seats:</div>
            </div>
        <div className="bg-white px-14">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <ul
                    role="list"
                    className="mx-auto mt-10 grid max-w-2xl grid-cols-2 gap-y-16 gap-x-8 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
                >
                    {seats.map((person) => (
                        <li key={person.name}>
                            <img className="mx-auto h-24 w-24 rounded-full" src={person.profilePicture ? person.profilePicture : require('../assets/images/DefaultUserProfileImage.png')} alt="" />
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                            {/* <p className="text-sm leading-6 text-gray-600">{person.role}</p> */}
                        </li>
                    ))}
                    <li>
                        <img className="mx-auto h-24 w-24 rounded-full" src={require('../assets/images/DefaultUserProfileImage.png')} alt="" />
                        <button onClick={() => setOpen(true)} className="font-roboto text-sm leading-6"><p className='text-sm font-roboto font-thin'>Add Seat</p></button>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}
