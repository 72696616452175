import * as BABYLON from 'babylonjs';
import { Scene, Engine, UniversalCamera } from 'babylonjs';
import 'babylonjs-loaders';
import axios from 'axios'
import { useAuth, useProject } from '../auth'
import { useParams, useLocation } from 'react-router-dom'

import { useEffect, useRef, useState } from 'react';

const S3_BUCKET_URL = 'https://portal-customersv2.s3.amazonaws.com/';

const BabylonScene = () => {
  const location = useLocation()
  const { user } = useAuth()
  const { project } = useProject()
  const { id } = useParams()
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })
  const canvasRef = useRef(null);
  const cameraRef = useRef(null);

  useEffect(() => {

    // Initialize Babylon.js engine and scene
    const engine = new Engine(canvasRef.current, true);
    const scene = new Scene(engine);

    // Add camera to the scene with keyboard and mouse controls
    const camera = new UniversalCamera('UniversalCamera', new BABYLON.Vector3(-3, 2, 11), scene);
    camera.setTarget(BABYLON.Vector3.Zero());
    camera.attachControl(canvasRef.current, true);

    // Attach a keydown event listener to the canvas element
    canvasRef.current.addEventListener('keydown', (event) => {
      if(camera.position.y < 1.5) {
        camera.position.y = 1.5
      }
      if (event.key === 'w') {
        camera.position.addInPlace(camera.getDirection(BABYLON.Axis.Z).scale(0.1));
      } else if (event.key === 's') {
        camera.position.subtractInPlace(camera.getDirection(BABYLON.Axis.Z).scale(0.1));
      } else if (event.key === 'a') {
        camera.position.addInPlace(camera.getDirection(BABYLON.Axis.X).scale(-0.15));
      } else if (event.key === 'd') {
        camera.position.addInPlace(camera.getDirection(BABYLON.Axis.X).scale(0.15));
      }
    });

    // Add lighting to the scene
    const light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene);

    // Create ground
    const ground = BABYLON.Mesh.CreateGround('ground', 30, 40, 1, scene);

    // Load skybox texture
    // const skyboxTexture = new BABYLON.CubeTexture(
    //   'https://assets.babylonjs.com/environments/skybox',
    //   scene,
    //   ['px.jpg', 'py.jpg', 'pz.jpg', 'nx.jpg', 'ny.jpg', 'nz.jpg']
    // );

    // Create skybox with grass texture
    // const skybox = BABYLON.MeshBuilder.CreateBox('skyBox', { size: 1000.0 }, scene);
    // const skyboxMaterial = new BABYLON.StandardMaterial('skyBoxMaterial', scene);
    // skyboxMaterial.backFaceCulling = false;
    // skyboxMaterial.reflectionTexture = skyboxTexture;
    // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    // skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
    // skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    // skybox.material = skyboxMaterial;
    // skybox.position.y = -100.0;

    // Skybox
    // var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {size:10.0}, scene);
    // var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
    // skyboxMaterial.backFaceCulling = false;
    // skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("textures/skybox4", scene);
    // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    // skyboxMaterial.disableLighting = true;
    // skybox.material = skyboxMaterial;

    // Load model from S3 bucket
    const modelUrl = `${project}`;
    console.log(modelUrl)
    BABYLON.SceneLoader.ImportMeshAsync('', modelUrl, '', scene).then((result) => {
      console.log("loaded model finished")
      const mesh = result.meshes[0];
      if (project.includes("Ram") || project.includes("hab")) {
        mesh.position.y = 0;
        mesh.position.x = 0;
        mesh.scaling = new BABYLON.Vector3(200, 200, 200)
      } else if(project.includes("L_T")){
        mesh.position.y = 3.5;
        mesh.position.x = 14;
        mesh.position.z = 10;
        mesh.scaling = new BABYLON.Vector3(1, 1, -1);

      } else {
        mesh.position.y = 0.1;
        mesh.position.x = 7;
        mesh.scaling = new BABYLON.Vector3(1, 1, -1);
      }
    });

    // Render loop
    engine.runRenderLoop(() => {
      if (camera) {
        if (canvasRef.current) {
          camera.attachControl(canvasRef.current, true);
        }
        scene.render();
      }
    });

    canvasRef.current.focus()


    // Cleanup
    return () => {
      engine.stopRenderLoop();
      camera.dispose();
      scene.dispose();
      engine.dispose();
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
    />
  );
};

export default BabylonScene;
