import React from 'react'
import './zuku-fill-button.css'

export default function ZukuFillButton(props) {
  return (
    <button className='zuku-fill-btn' onClick={props.onClick}>
      {props.text}
    </button>
  )
}
