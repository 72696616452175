import React, { useState, useEffect } from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../auth'
import {
  CircularProgress,
  Grid,
  TextField,
  Button,
  Snackbar,
} from '@mui/material'
import '@fontsource/roboto'

const classes = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  text: {
    flexGrow: 1,
    color: '#262626',
    textOverflow: 'ellipsis',
    fontSize: '43px',
    fontFamily: 'Roboto',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
  accountText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '5px',
  },
}

export default function SignUpPasswordPage({ onSignup }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [viewers, setViewers] = useState([]);
  const [viewerIds, setViewerIds] = useState([]);
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })
  const params = new URLSearchParams(location.search)
  const projectId = params.get('project_id')

  const iHaveAccount = () => {
    navigate('/login')
  }

  const { firstName, lastName, phone, company, email } = location.state

  const signUp = async () => {
    try {
      const newUser = await createUser()
      const initialViewerIds = await getViewers(projectId, newUser)
      console.log(newUser)
      await addViewer(newUser, projectId, initialViewerIds)
      console.log('user added')
      navigate('/')
    } catch(e) {
      console.log(e)
    }

  }

  const createUser = async () => {
    console.log('creating user')
    try {
      const username = email
      setStatus({ loading: true, error: false, message: '' })
      return await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/local/register`,
        {
          phone,
          email,
          company,
          password,
          role: 3,
          name: `${firstName} ${lastName}`,
          username,
        }
      )
      .then((newUser) => {
        localStorage.setItem('zukeUser', JSON.stringify(newUser.data))
        onSignup(newUser.data)
        console.log('user created')
        return newUser.data
      })
      .catch((e) => {
        console.log(e)
      })

      // setStatus({ loading: false })

      // navigate('/')
    } catch (error) {
      console.log(error)
      let errorMessage = error?.response?.data?.message[0]?.messages[0]?.message
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: errorMessage
          ? errorMessage
          : 'An unexpected error has occurred',
      })
    }
  }

  const getViewers = async (projectId, newUser) => {
    console.log('getting users')
    console.log(newUser)
    const headers = {
      'Authorization': `Bearer ${newUser.jwt}`
    };
      return await axios.get(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
      { headers })
      .then((singleProject) => {
        const initialViewers = singleProject.data.users_permissions_users.map(user => {
          return user.email;
        });
        setViewers(initialViewers);
        const InitialViewerIds = singleProject.data.users_permissions_users.map(user => {
          return user.id;
        });
        setViewerIds(InitialViewerIds);
        console.log('user got')
        return InitialViewerIds
      })
      .catch((e) => {
        console.log('errror getting viewers', e)
      })
  }

  const addViewer = async (viewer, projectId, InitialViewerIds) => {
    let data = {}
    try {
      data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/local`,
        {
          identifier: "ofasx1@gmail.com",
          password: 'Test1234'
        }
      )
    } catch(e) {
      console.log(e)
    }
    console.log('add user', viewer)
    console.log(data)
    const headers = {
      'Authorization': `Bearer ${data.data.jwt}`
    };
      return await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
        {
          users_permissions_users: [...InitialViewerIds,
          {
            "id": viewer.user.id
          },
          ]
        }, { headers });
  };

  const onChangePassword = (e) => {
    let password = e.target.value.replace(/[\n]/g, '').trim()
    const maxLength = 50
    if (password.length > maxLength) {
      password = password.substring(0, maxLength)
    }
    setPassword(password)
  }

  const forgotPassword = () => {
    navigate('/forgot-password')
  }

  const onCloseSnack = () => {
    setStatus({
      message: '',
      error: false,
      isOpenSnackStatus: false,
    })
  }

  const setDisableButton = () => {
    if (password.length < 6) {
      setIsDisableButton(true)
    }
    if (password.length > 5) {
      setIsDisableButton(false)
    }
  }

  useEffect(() => {
    setDisableButton()
  }, [password])

  return (
    <div style={{ width: '100vw', height: '100vh', flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center' }}>
      {status.loading ? (
        <div>
          <Grid
            container
            spacing={3}
            paddingLeft='26px'
            paddingRight={'26px'}
            justifyContent={'center'}
            style={{ paddingTop: '30vh' }}
          >
            <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid
            container
            spacing={3}
            paddingLeft='26px'
            paddingRight={'26px'}
            justifyContent={'center'}
            style={{ paddingTop: '30vh' }}
          >
            <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
              <div style={classes.text}>Please Enter a Password</div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            paddingLeft='45px'
            paddingRight={'45px'}
            justifyContent={'center'}
            marginTop={'40px'}
          >
            <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
              <TextField
                type='password'
                placeholder='Password'
                onChange={onChangePassword}
                value={password}
                style={classes.textFieldZuku}
                inputProps={{
                  style: {
                    height: '40px',
                    textAlign: 'center',
                    fontSize: '18px',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            paddingLeft='45px'
            paddingRight={'45px'}
            justifyContent={'center'}
            marginTop={'10px'}
          >
            <Grid item xs={12} sm={12} md={1.6} ls={1.6} xl={1.6}></Grid>
            <Grid item xs={12} sm={12} md={3} ls={3} xl={3}>
              <p onClick={iHaveAccount} style={classes.accountText}>
                Already Have an Account?
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              ls={1.4}
              xl={1.4}
              style={classes.button}
              sx={{
                display: { xs: 'flex', md: 'flex' },
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'right',
                  ls: 'right',
                  xl: 'right',
                  sx: 'right',
                },
              }}
            >
              <Button
                variant='contained'
                sx={{ textTransform: 'none', fontSize: '20px' }}
                style={classes.actionButton}
                onClick={signUp}
                disabled={isDisableButton}
              >
                Next
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            paddingLeft='45px'
            paddingRight={'45px'}
            justifyContent={'center'}
            marginTop={'10px'}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              ls={3}
              xl={3}
              display='flex'
              sx={{
                display: { xs: 'flex', md: 'flex' },
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                  ls: 'flex-start',
                  xl: 'flex-start',
                  sx: 'flex-start',
                },
              }}
            >
              <input
                style={classes.checkbox}
                type='checkbox'
                id='rememberMe'
                name='rememberMe'
                value='RememberMe'
              />
              <p style={classes.accountText}>Remember Me</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              ls={3}
              xl={3}
              sx={{
                display: { xs: 'flex', md: 'flex' },
                justifyContent: {
                  xs: 'center',
                  md: 'right',
                  ls: 'right',
                  xl: 'right',
                  sx: 'right',
                },
              }}
              onClick={forgotPassword}
            >
              <p style={classes.accountText}>Forgot Password?</p>
            </Grid>
          </Grid>
          <Grid container spacing={3} paddingLeft='50px' paddingRight={'50px'}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              ls={6}
              xl={6}
              style={classes.footerIcon}
              sx={{
                display: { xs: 'none', sm: 'flex' },
              }}
            >
            <img src={zukeIcon} className='sm:block hidden' alt="SVG logo image"/>
            </Grid>
          </Grid>
          <Snackbar
            message={status.message}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={2000}
            onClose={() => onCloseSnack(false)}
            open={status.isOpenSnackStatus}
          />
        </div>
      )}
    </div>
  )
}
