import React from 'react'
import './zuku-textfield.css'

export default function ZukuTextfield(props) {
  return (
    <input
      type={props.type}
      className='zuku-textfield'
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
    />
  )
}
