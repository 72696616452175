import { useState } from 'react'
import Navbar from '../components/Navbar'
import Seats from '../components/Seats'
import AdminFormSection from '../components/AdminFormSection'

export default function AdminPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
    <Navbar></Navbar>
    <Seats />
    <AdminFormSection></AdminFormSection>
      </>
  )
}
