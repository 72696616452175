import React, { useState, useEffect } from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Snackbar, CircularProgress } from '@mui/material'
import '@fontsource/roboto'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import axios from 'axios'

const classes = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  text: {
    flexGrow: 1,
    color: '#262626',
    textOverflow: 'ellipsis',
    fontSize: '43px',
    fontFamily: 'Roboto',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
  accountText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  actionButton: {
    width: '130px',
    height: '43px',
    backgroundColor: 'rgba(179, 16, 236, 1)',
    color: 'white',
    fontWeight: '300',
    lineHeight: 'normal',
    borderRadius: '0% !important',
  },
  textFieldZuku: {
    color: 'rgba(38, 38, 38, 1)',
    backgroundColor: 'rgba(251, 243, 249, 1)',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}

export default function SignUpEmailPage() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState({
    loading: false,
    message: '',
    error: false
  })

  const onClickNextButton = async () => {

    try {
      setStatus({ loading: true, message:"", error: false })
      setIsDisableButton(true)
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users?email=${email}`);

      const user = await response.data
      console.log(user)
      if (user[0]?.email === email) {
        setStatus({ error: true, message: 'A user for this email already exists', loading: false })
      } else {
        if(params.has('project_id')) {
          navigate(`/sign-up-password?project_id=${params.get('project_id')}`, { state: { ...location.state, email } })
        } else {
          navigate('/sign-up-password', { state: { ...location.state, email } })
        }
      }
      console.log(status.error)
      setDisableButton(false)

    } catch (error) {
      setStatus({ error: true, message: 'there was an error checking for this email', loading: false })
      setDisableButton(false)
      throw new Error("an error occured");
    }

  }

  const onCloseSnack = () => {
    setStatus({
      message: '',
      error: false,
      isOpenSnackStatus: false,
    })
  }

  const iHaveAccount = () => {
    navigate('/login')
  }

  const onChangeEmail = (e) => {
    let email = e.target.value.replace(/[^a-zA-Z0-9@_.-]/g, '').trim()
    const maxLength = 50
    if (email.length > maxLength) {
      email = email.substring(0, maxLength)
    }
    setEmail(email)
  }

  const setDisableButton = () => {
    if (email.length < 6) {
      setIsDisableButton(true)
    }
    if (email.length > 5) {
      setIsDisableButton(false)
    }
  }

  useEffect(() => {
    setDisableButton()
  }, [email])

  return (
    <>

      <div style={{
        width: '100vw', height: '100vh', flexGrow: 1,
        textAlign: 'center',
        paddingTop: '50hv',
        minWidth: '350px',
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center'
      }}>
        <Grid
          container
          spacing={3}
          paddingLeft='26px'
          paddingRight={'26px'}
          justifyContent={'center'}
          style={{ paddingTop: '30vh' }}
        >
          <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
            <div style={classes.text}>Please Enter Your Email</div>
            {status.loading ?
        <CircularProgress></CircularProgress> : null
      }
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          paddingLeft='45px'
          paddingRight={'45px'}
          justifyContent={'center'}
          marginTop={'40px'}
        >
          <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
            <TextField
              error={status.error}
              helperText={status.error ? "email already taken" : undefined}
              type='text'
              placeholder='E-mail Address'
              onChange={onChangeEmail}
              value={email}
              style={classes.textFieldZuku}
              inputProps={{
                style: {
                  height: '40px',
                  textAlign: 'center',
                  fontSize: '18px',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          paddingLeft='45px'
          paddingRight={'45px'}
          justifyContent={'center'}
          marginTop={'10px'}
        >
          <Grid item xs={12} sm={12} md={1.6} ls={1.6} xl={1.6}></Grid>
          <Grid item xs={12} sm={12} md={3} ls={3} xl={3}>
            <p onClick={iHaveAccount} style={classes.accountText}>
              Already Have an Account?
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={1.4}
            ls={1.4}
            xl={1.4}
            style={classes.button}
          >
            <Button
              variant='contained'
              sx={{ textTransform: 'none', fontSize: '20px' }}
              style={classes.actionButton}
              onClick={onClickNextButton}
              disabled={isDisableButton}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} paddingLeft='50px' paddingRight={'50px'}>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            ls={6}
            xl={6}
            style={classes.footerIcon}
          >
            <img src={zukeIcon} className='sm:block hidden' alt="SVG logo image" />
          </Grid>
        </Grid>
      </div>
    </>
  )
}
