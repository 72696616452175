import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import { useAuth } from '../auth'
import { CircularProgress, Snackbar, Grid } from '@mui/material'

export default function ForgotPasswordModal(props) {
    const user = useAuth()

    const cancelButtonRef = useRef(null)
    const [newOption, setNewOption] = useState('');
    const [status, setStatus] = useState({
        loading: false,
        isOpenSnackStatus: false,
        message: '',
    })
    let headers = ''
    if (user.user.user) {
        headers = {
            'Authorization': `Bearer ${user.user.user.jwt}`
        };
    }

    const onCloseSnack = () => {
        setStatus({
            message: '',
            error: false,
            isOpenSnackStatus: false,
        })
    }

    const sendPasswordReset = async (e) => {
        e.preventDefault()
        console.log(newOption)
        setStatus({ loading: true, error: false, message: '' })
        if (props.option === 'password') {
            axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, {
                email: newOption
            })
                .then(response => {
                    setStatus({ loading: false, error: false, isOpenSnackStatus: true, message: "reset password email sent" })
                    setNewOption("")
                })
                .catch(error => {
                    console.log(error)
                    setStatus({ loading: false, error: false, isOpenSnackStatus: true, message: "an error ocurred" })
                    setNewOption("")
                });
        } else if (props.option === 'name') {
            axios.put(`${process.env.REACT_APP_BASE_URL}/users/${user.user.user.user.id}`, {
                name: newOption
            }, { headers })
                .then(response => {
                    setStatus({ loading: false })
                    props.updateOption(props.option, newOption)
                    props.isOpen(false)
                    const newUser = JSON.parse(localStorage.getItem("zukeUser"))
                    newUser.user.name = newOption
                    localStorage.setItem("zukeUser", JSON.stringify(newUser))
                    setNewOption("")
                })
                .catch(error => {
                    setStatus({ loading: false })
                });
        } else if (props.option === 'phone') {
            axios.put(`${process.env.REACT_APP_BASE_URL}/users/${user.user.user.user.id}`, {
                phone: newOption
            }, { headers })
                .then(response => {
                    setStatus({ loading: false })
                    props.updateOption(props.option, newOption)
                    props.isOpen(false)
                    const newUser = JSON.parse(localStorage.getItem("zukeUser"))
                    newUser.user.phone = newOption
                    localStorage.setItem("zukeUser", JSON.stringify(newUser))
                    setNewOption("")
                })
                .catch(error => {
                    setStatus({ loading: false })
                });
        } else if (props.option === 'email') {
            axios.put(`${process.env.REACT_APP_BASE_URL}/users/${user.user.user.id}`, {
                email: newOption
            }, { headers })
                .then(response => {
                    setStatus({ loading: false })
                    props.updateOption(props.option, newOption)
                    props.isOpen(false)
                    const newUser = JSON.parse(localStorage.getItem("zukeUser"))
                    newUser.user.email = newOption
                    localStorage.setItem("zukeUser", JSON.stringify(newUser))
                    setNewOption("")
                })
                .catch(error => {
                    setStatus({ loading: false })
                    setNewOption("")
                });
        } else if (props.option === 'CompanyName') {
            axios.put(`${process.env.REACT_APP_BASE_URL}/companies/${user.user.user.user.companies[0].id}`, {
                name: newOption
            }, { headers })
                .then(response => {
                    setStatus({ loading: false })
                    props.updateOption(props.option, newOption)
                    props.isOpen(false)
                    const newUser = JSON.parse(localStorage.getItem("zukeUser"))
                    newUser.user.companies[0].name = newOption
                    localStorage.setItem("zukeUser", JSON.stringify(newUser))
                    setNewOption("")

                })
                .catch(error => {
                    setStatus({ loading: false })
                    setNewOption("")
                });
        } else if (props.option === 'CompanyAddress') {
            axios.put(`${process.env.REACT_APP_BASE_URL}/companies/${user.user.user.user.companies[0].id}`, {
                address: newOption
            }, { headers })
                .then(response => {
                    setStatus({ loading: false })
                    props.updateOption(props.option, newOption)
                    props.isOpen(false)
                    const newUser = JSON.parse(localStorage.getItem("zukeUser"))
                    newUser.user.companies[0].address = newOption
                    localStorage.setItem("zukeUser", JSON.stringify(newUser))
                    setNewOption("")
                })
                .catch(error => {
                    setStatus({ loading: false })
                    setNewOption("")
                });
        } else if (props.option === 'CompanyPhone') {
            axios.put(`${process.env.REACT_APP_BASE_URL}/companies/${user.user.user.user.companies[0].id}`, {
                phone: newOption
            }, { headers })
                .then(response => {
                    setStatus({ loading: false })
                    props.updateOption(props.option, newOption)
                    props.isOpen(false)
                    const newUser = JSON.parse(localStorage.getItem("zukeUser"))
                    newUser.user.companies[0].phone = newOption
                    localStorage.setItem("zukeUser", JSON.stringify(newUser))
                    setNewOption("")
                })
                .catch(error => {
                    setStatus({ loading: false })
                    setNewOption("")
                });
        }
    }

    return (
        <>
            <Transition.Root show={props.open} as={Fragment} >
                <Dialog as="div" className="relative z-10 w-full max-w-md" onClose={props.isOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="w-1/2 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all">
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Enter your {props.option === 'password' ? 'email address' : props.option.replace(/([A-Z])/g, ' $1').trim()}
                                            </Dialog.Title>
                                        </div>
                                    </div>
                                    <form className="mt-8 space-y-6" onSubmit={sendPasswordReset} data-cy="ForgotPasswordModal">
                                        {status.loading &&
                                            <div>
                                                <CircularProgress className='justify-items-center' />
                                            </div>
                                        }
                                        <div className="space-y-6 px rounded-md shadow-sm">

                                            {
                                                props.option === "password" &&
                                                <div>
                                                    <input
                                                        id="email-address"
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        required
                                                        className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        placeholder="email address"
                                                        value={newOption}
                                                        onChange={(event) => setNewOption(event.target.value)}
                                                        data-cy="newEmail"
                                                    />
                                                </div>
                                            }
                                            {props.option !== "password" &&
                                                <div>
                                                    <input
                                                        id={props.option}
                                                        name={props.option}
                                                        type="text"
                                                        autoComplete={props.option}
                                                        required
                                                        className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        placeholder={props.option}
                                                        value={newOption}
                                                        onChange={(event) => setNewOption(event.target.value)}
                                                        data-cy={props.option}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="mt-5 sm:mt-6 flex flex-col items-center">
                                            <button
                                                type="submit"
                                                className="inline-flex border-transparent bg-zuke-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm"
                                                data-cy="submit"
                                            >
                                                CHANGE {props.option === 'password' ? "PASSWORD" : props.option.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>

                </Dialog>
            </Transition.Root>
            <Snackbar
                message={status.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                autoHideDuration={2000}
                onClose={() => onCloseSnack(false)}
                open={status.isOpenSnackStatus}
            />
        </>
    )
}
