import { useAuth } from '../auth'
import React, { useState } from 'react'
import ForgotPasswordModal from './ForgotPasswordModal'
import { PencilIcon } from '@heroicons/react/24/outline'

const AdminFormSection = () => {
    const user = useAuth()
    const [open, setOpen] = useState(false)
    const [option, setOption] = useState('')
    const [companyName, setCompanyName] = useState(JSON.parse(localStorage.getItem('zukeUser')).user.companies[0].name || user.user.user.user.companies[0]?.name || 'company name')
    const [companyPhone, setCompanyPhone] = useState(JSON.parse(localStorage.getItem('zukeUser')).user.companies[0].phone || user.user.user.user.companies[0]?.phone || 'phone number')
    const [companyAddress, setCompanyAddress] = useState(JSON.parse(localStorage.getItem('zukeUser')).user.companies[0].address || user.user.user.user.companies[0]?.address || 'address')
    const options = {
        CompanyPhone: "CompanyPhone",
        CompanyName: "CompanyName",
        CompanyAddress: "CompanyAddress"
    }

    const isOpen = (isOpen) => {
        // setOpen(!open)
        setOpen(isOpen)
    }

    const openModal = (option) => {
        setOption(option)
        setOpen(true)
    }

    const updateOption = (option, newOption) => {
        console.log(option)
        console.log(newOption)
        if (option === "CompanyName") {
            setCompanyName(newOption);
        } else if (option === "CompanyAddress") {
            setCompanyAddress(newOption)
        } else if (option === "CompanyPhone") {
            setCompanyPhone(newOption)
        }
    };

    return (
        <>
            <ForgotPasswordModal
                updateOption={updateOption}
                option={option}
                isOpen={isOpen}
                open={open}
                data-cy="ForgotPasswordModal"
            />
            <section className="p-4 py-12">
                <div className="flex flex-col p-6">
                    <div className="pl-10 text-lg font-roboto">Company Information:</div>
                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text"
                                className="h-14 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder={companyName || 'company name'} disabled
                                data-cy="companyName"
                            />
                        </div>
                    </div>

                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text" className="h-14 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder={companyAddress || "company address"}
                                disabled
                                data-cy="companyAddress"
                            />
                            <button
                                onClick={() => openModal(options.CompanyAddress)}
                                className="h-10  w-1/5 py-2 px-4 mr-2"
                                data-cy="editCompanyAddress"
                            >
                                <PencilIcon className='h-8'></PencilIcon>
                            </button>

                        </div>
                    </div>
                </div>
                <div className="flex pl-24 mb-4">
                    <div className="w-3/5">
                        <div className="flex mb-4 space-x-28 items-center">
                            <input
                                type="text"
                                className="h-14 pr-6 bg-zuke-gray w-1/2 py-2 px-4 mr-2"
                                placeholder={companyPhone || "Phone Number"}
                                disabled
                                data-cy="companyPhone"
                            />
                            <button
                                onClick={() => openModal(options.CompanyPhone)}
                                className="h-10 w-1/5 py-2 px-4 mr-2"
                                data-cy="editCompanyPhone"
                            >
                                <PencilIcon className='h-8'></PencilIcon>
                            </button>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminFormSection