import React, { useState, useEffect } from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import '@fontsource/roboto'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'


const classes = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  text: {
    flexGrow: 1,
    color: '#262626',
    textOverflow: 'ellipsis',
    fontSize: '43px',
    fontFamily: 'Roboto',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
  actionButton: {
    width: '130px',
    height: '43px',
    backgroundColor: 'rgba(179, 16, 236, 1)',
    color: 'white',
    fontWeight: '300',
    lineHeight: 'normal',
    borderRadius: '0% !important',
  },
  textFieldZuku: {
    color: 'rgba(38, 38, 38, 1)',
    backgroundColor: 'rgba(251, 243, 249, 1)',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}

export default function SignUpNamePage() {
  const location = useLocation()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isDisableButton, setIsDisableButton] = useState(true)
  let navigate = useNavigate()
  const params = new URLSearchParams(location.search)


  const onClickNextButton = () => {
    if(params.has('project_id')) {
      navigate(`/sign-up-phone-and-company?project_id=${params.get('project_id')}`, {
        state: { ...location.state, firstName, lastName },
      })
    } else {
      navigate('/sign-up-phone-and-company', {
        state: { ...location.state, firstName, lastName },
      })
    }
  }

  const onChangeFirstName = (e) => {
    let firstName = e.target.value.trim()
    const maxLength = 100
    if (firstName.length > maxLength) {
      firstName = firstName.substring(0, maxLength)
    }
    setFirstName(firstName)
  }

  const onChangeLastName = async (e) => {
    let lastName = e.target.value.trim()
    const maxLength = 100
    if (lastName.length > maxLength) {
      lastName = lastName.substring(0, maxLength)
    }
    setLastName(lastName)
  }

  const setDisableButton = () => {
    if (firstName.length > 0 && lastName.length > 0) {
      setIsDisableButton(false)
    }
  }

  useEffect(() => {
    setDisableButton()
  }, [firstName, lastName, setDisableButton])

  return (
    <div style={{ width: '100vw', height: '100vh', flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center' }}>
      <Grid
        container
        spacing={3}
        paddingLeft='26px'
        paddingRight={'26px'}
        justifyContent={'center'}
        style={{ paddingTop: '30vh' }}
      >
        <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
          <div style={classes.text}>
            Please Enter Your First &amp; Last Name
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        paddingLeft='45px'
        paddingRight={'45px'}
        justifyContent={'center'}
        marginTop={'40px'}
      >
        <Grid item xs={12} sm={12} md={3} ls={3} xl={3}>
          <TextField
            placeholder='First Name'
            type='text'
            onChange={onChangeFirstName}
            style={classes.textFieldZuku}
            inputProps={{
              style: {
                height: '40px',
                textAlign: 'center',
                fontSize: '18px',
              },
            }}
            value={firstName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} ls={3} xl={3}>
          <TextField
            placeholder='Last Name'
            type='text'
            onChange={onChangeLastName}
            style={classes.textFieldZuku}
            inputProps={{
              style: {
                height: '40px',
                textAlign: 'center',
                fontSize: '18px',
              },
            }}
            value={lastName}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        paddingLeft='45px'
        paddingRight={'45px'}
        justifyContent={'center'}
        marginTop={'10px'}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          ls={6}
          xl={6}
          style={classes.button}
        >
          <Button
            variant='contained'
            sx={{ textTransform: 'none', fontSize: '20px' }}
            style={classes.actionButton}
            onClick={onClickNextButton}
            disabled={isDisableButton}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} paddingLeft='50px' paddingRight={'50px'}>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          ls={6}
          xl={6}
          style={classes.footerIcon}
        >
        <img src={zukeIcon} className='sm:block hidden' alt="SVG logo image"/>
        </Grid>
      </Grid>
    </div>
  )
}
