import React, { useState, useEffect } from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import axios from 'axios'
import { CircularProgress, Snackbar, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth'
import '@fontsource/roboto'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import ForgotPasswordModal from '../../components/ForgotPasswordModal'
import { useLocation } from 'react-router-dom';

const classes = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  text: {
    flexGrow: 1,
    color: '#262626',
    textOverflow: 'ellipsis',
    fontSize: '43px',
    fontFamily: 'Roboto',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
  accountText: {
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0.05em',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '5px',
  },
  actionButton: {
    width: '130px',
    height: '43px',
    backgroundColor: 'rgba(179, 16, 236, 1)',
    color: 'white',
    fontWeight: '300',
    lineHeight: 'normal',
    borderRadius: '0% !important',
  },
  textFieldZuku: {
    color: 'rgba(38, 38, 38, 1)',
    backgroundColor: 'rgba(251, 243, 249, 1)',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}

export default function LoginPage({ onLogin }) {
  const location = useLocation();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { user } = useAuth()
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [viewers, setViewers] = useState([]);
  const [viewerIds, setViewerIds] = useState([]);
  const [params, setParams] = useState(null)
  const [projectId, setProjectId] = useState("")
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })
  const [open, setOpen] = useState(false)
  let navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(true)

  useEffect(() => {
    const fetchViewers = async () => {
      const newParams = new URLSearchParams(location.search);
      console.log(newParams)
      setParams(newParams)
      const projectId = newParams.get('project_id')
      setDisableButton()
      if (Object.keys(user).length !== 0 && newParams.has('project_id')) {
        console.log('user logged in')
        setProjectId(projectId)
        try {
          const initialViewerIds = await getViewers(projectId)
          await addViewer(user.user.user.id, projectId, initialViewerIds)
          navigate(`/projects/${projectId}`)

        } catch(e) {
          console.log(e)
        }
      } else if (newParams.has('project_id')) {
        console.log('user not logged in')
        navigate(`/welcome?project_id=${projectId}`)
      }
      else {
        console.log('idk')
      }
    }

    fetchViewers()

  }, [email, password, user, location])

  const getViewers = async (projectId) => {
    const headers = {
      'Authorization': `Bearer ${user.user.jwt}`
    };
      return await axios.get(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
      { headers })
      .then((singleProject) => {
        const initialViewers = singleProject.data.users_permissions_users.map(user => {
          return user.email;
        });
        setViewers(initialViewers);
        const InitialViewerIds = singleProject.data.users_permissions_users.map(user => {
          return user.id;
        });
        setViewerIds(InitialViewerIds);
        return InitialViewerIds
      })
      .catch((e) => {
        console.log('errror getting viewers', e)
      })
  }

  const addViewer = async (viewer, projectId, InitialViewerIds) => {
    let data = {}

    try {
      setStatus({ loading: true, error: false, message: '' })
      data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/local`,
        {
          identifier: 'ofasx1@gmail.com',
          password: 'Test1234'
        }
      )
      setStatus({ loading: false })
    } catch(e) {
      console.log(e)
    }
    const headers = {
      'Authorization': `Bearer ${data.data.jwt}`
    };
      return await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
        {
          users_permissions_users: [...InitialViewerIds,
          {
            "id": viewer
          },
          ]
        }, { headers });
  };

  const isOpen = (isOpen) => {
    // setOpen(!open)
    setOpen(isOpen)
  }

  const signIn = async () => {
    console.log("trying to log in")
    try {
      setStatus({ loading: true, error: false, message: '' })
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/local`,
        {
          identifier: email,
          password,
        }
      )
      setStatus({ loading: false })
      if (rememberMe === true) {
        console.log(data)
        localStorage.setItem('rememberMe', true)
        localStorage.setItem('zukeUser', JSON.stringify(data))
        //TODO: What's going on here?
        // addViewer(data.user.id)
        onLogin(data)
      }
      onLogin(data)
    } catch (error) {
      console.log(error)
      let errorMessage = error?.response?.data?.message[0]?.messages[0]?.message
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: errorMessage
          ? errorMessage
          : 'An unexpected error has occurred',
      })
    }
  }

  const onChangeUser = (e) => {
    let email = e.target.value.replace(/[^a-zA-Z0-9@_.-]/g, '').trim()
    const maxLength = 50
    if (email.length > maxLength) {
      email = email.substring(0, maxLength)
    }
    setEmail(email)
  }

  const onChangePassword = (e) => {
    let password = e.target.value.replace(/[\n]/g, '').trim()
    const maxLength = 50
    if (password.length > maxLength) {
      password = password.substring(0, maxLength)
    }
    setPassword(password)
  }

  const forgotPassword = () => {
    navigate('/forgot-password')
  }

  const onCloseSnack = () => {
    setStatus({
      message: '',
      error: false,
      isOpenSnackStatus: false,
    })
  }

  const iDontHaveAccount = () => {
    if(params.has('project_id')) {
      navigate(`welcome?project_id=${projectId}`)
    } else {
      navigate('/welcome')
    }
  }

  const onChangeRememberMe = () => {
    setRememberMe(!rememberMe)
  }

  const setDisableButton = () => {
    if (email.length < 6 || password.length < 5) {
      setIsDisableButton(true)
    }
    if (email.length > 5 && password.length > 4) {
      setIsDisableButton(false)
    }
  }

  return (
    <>
      <ForgotPasswordModal option="password" isOpen={isOpen} open={open}></ForgotPasswordModal>

      <div style={{
        width: '100vw', height: '100vh', flexGrow: 1,
        textAlign: 'center',
        paddingTop: '50hv',
        minWidth: '350px',
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center'
      }}>
        {status.loading ? (
          <div>
            <Grid
              container
              spacing={3}
              paddingLeft='26px'
              paddingRight={'26px'}
              justifyContent={'center'}
              style={{ paddingTop: '30vh' }}
            >
              <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
                <CircularProgress />
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid
              container
              spacing={3}
              paddingLeft='26px'
              paddingRight={'26px'}
              justifyContent={'center'}
              style={{ paddingTop: '30vh' }}
            >
              <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
                <div style={classes.text}>Please Log In</div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              paddingLeft='45px'
              paddingRight={'45px'}
              justifyContent={'center'}
              marginTop={'40px'}
            >
              <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
                <TextField
                  type='text'
                  placeholder='E-mail Address'
                  value={email}
                  onChange={onChangeUser}
                  style={classes.textFieldZuku}
                  inputProps={{
                    style: {
                      height: '40px',
                      textAlign: 'center',
                      fontSize: '18px',
                    },
                  }}
                  data-cy='email'
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              paddingLeft='45px'
              paddingRight={'45px'}
              justifyContent={'center'}
              marginTop={'40px'}
            >
              <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
                <TextField
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={onChangePassword}
                  style={classes.textFieldZuku}
                  inputProps={{
                    style: {
                      height: '40px',
                      textAlign: 'center',
                      fontSize: '18px',
                    },
                  }}
                  data-cy='password'
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              paddingLeft='45px'
              paddingRight={'45px'}
              justifyContent={'center'}
              marginTop={'10px'}
            >
              <Grid item xs={12} sm={12} md={1.6} ls={1.6} xl={1.6}></Grid>
              <Grid item xs={12} sm={12} md={3} ls={3} xl={3}>
                <p onClick={iDontHaveAccount} style={classes.accountText}>
                  You don't have an account?
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1.4}
                ls={1.4}
                xl={1.4}
                style={classes.button}
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  justifyContent: {
                    xs: 'center',
                    sm: 'center',
                    md: 'right',
                    ls: 'right',
                    xl: 'right',
                    sx: 'right',
                  },
                }}
              >
                <Button
                  variant='contained'
                  sx={{ textTransform: 'none', fontSize: '20px' }}
                  style={classes.actionButton}
                  onClick={signIn}
                  disabled={isDisableButton}
                  data-cy='login'
                >
                  Login
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              paddingLeft='45px'
              paddingRight={'45px'}
              justifyContent={'center'}
              marginTop={'10px'}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                ls={3}
                xl={3}
                display='flex'
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  justifyContent: {
                    xs: 'center',
                    sm: 'center',
                    md: 'flex-start',
                    ls: 'flex-start',
                    xl: 'flex-start',
                    sx: 'flex-start',
                  },
                }}
              >
                <input
                  style={classes.checkbox}
                  type='checkbox'
                  id='rememberMe'
                  name='rememberMe'
                  value='RememberMe'
                  checked={rememberMe}
                  onChange={onChangeRememberMe}
                />
                <p style={classes.accountText} onClick={onChangeRememberMe}>
                  Remember Me
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                ls={3}
                xl={3}
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  justifyContent: {
                    xs: 'center',
                    md: 'right',
                    ls: 'right',
                    xl: 'right',
                    sx: 'right',
                  },
                }}
                onClick={() => setOpen(true)}
              >
                <p style={classes.accountText}>Forgot Password?</p>
              </Grid>
            </Grid>
            <Grid container spacing={3} paddingLeft='50px' paddingRight={'50px'}>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                ls={6}
                xl={6}
                style={classes.footerIcon}
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                }}
              >
                <img src={zukeIcon} className='md:hidden' alt="SVG logo image" />
              </Grid>
            </Grid>
          </div>
        )}

        <Snackbar
          message={status.message}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={2000}
          onClose={() => onCloseSnack(false)}
          open={status.isOpenSnackStatus}
        />
      </div>
    </>
  )
}
