import React, { useState } from 'react'
import { Grid } from '@mui/material'
import '@fontsource/roboto'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../../auth'
import axios from 'axios'

const classess = {
  root: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  button: {
    justifyContent: 'right',
    alignItems: 'right',
    display: 'flex',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    maxWidth: '581px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '50px',
    lineHeight: '59px',
    textAlign: 'center',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  subtitle: {
    maxWidth: '633px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  mostPopular: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '25px',
    lineHeight: '29px',
    textAlign: 'center',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  cardArea: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '300px',
  },
  textFieldZuku: {
    color: 'rgba(38, 38, 38, 1)',
    backgroundColor: 'rgba(251, 243, 249, 1)',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: 'normal',
    letterSpacing: '0.05em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
}

const pricing = {
  tiers: [
    {
      title: 'Standard',
      priceCode: process.env.REACT_APP_STANDARD_PRICE,
      price: 195,
      frequency: '/month',
      description: 'The essentials to provide your best work for clients.',
      features: ['2 GB file storage', 'unlimited viewers'],
      cta: 'START MY 14 DAY FREE TRIAL',
      mostPopular: false,
    },
    {
      title: 'Pro',
      priceCode: process.env.REACT_APP_PRO_PRICE,
      price: 255,
      frequency: '/month',
      description: 'A plan that scales with your rapidly growing business.',
      features: [
        '5 GB file storage',
        'unlimited viewers'
      ],
      cta: 'START MY 14 DAY FREE TRIAL',
      mostPopular: true,
    },
    {
      title: 'Premium',
      priceCode: process.env.REACT_APP_PREMIUM_PRICE,
      price: 295,
      frequency: '/month',
      description: 'Dedicated support and infrastructure for your company.',
      features: [
        '10 GB file storage',
        'unlimited viewers',
        '24/7 customer support'
      ],
      cta: 'START MY 14 DAY FREE TRIAL',
      mostPopular: false,
    },
  ],
}



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SelectSubscriptionPage() {
  const { user } = useAuth()
  const [status, setStatus] = useState({
    loading: false,
    isOpenSnackStatus: false,
    message: '',
  })

  const payment = async (priceCode) => {
    // event.preventDefault()
    const price = priceCode
    const { jwt } = user.user

    // let params = new URLSearchParams(document.location.search);
    // let price = params.get("price");

    try {
      setStatus({ loading: true })
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscriptions/payment`,
        {
          price,
          email: user.user.user.email
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      const data = await response
      // Handle success.
      setStatus({ loading: false })
      window.location = data.data
    } catch (error) {
      const errorMessage = error?.response?.data?.message[0]?.messages[0]?.message
      setStatus({
        loading: false,
        isOpenSnackStatus: true,
        message: errorMessage
          ? errorMessage
          : 'An unexpected error has occurred',
      })    }
  }

  return (
    <div style={{ width: '100vw', height: '100vh', flexGrow: 1,
    textAlign: 'center',
    paddingTop: '50hv',
    minWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center' }}>
      <div>
        <Grid
          container
          spacing={3}
          paddingLeft='26px'
          paddingRight={'26px'}
          justifyContent={'center'}
          style={{ paddingTop: '10vh' }}
        >
          <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
            <div style={classess.titleContainer}>
              <p style={classess.title}>Zuke uses a Subscription Model.</p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          paddingLeft='26px'
          paddingRight={'26px'}
          justifyContent={'center'}
          style={{ paddingTop: '28px' }}
        >
          <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
            <div style={classess.subtitleContainer}>
              <p style={classess.subtitle}>
                Please choose a plan to continue. You will be asked to enter
                credit card information on the next screen, as well as general
                billing information.
              </p>
            </div>
          </Grid>
        </Grid>
        {/* <Grid
          container
          spacing={3}
          paddingLeft='26px'
          paddingRight={'26px'}
          justifyContent={'center'}
          style={{ paddingTop: '31px' }}
        >
          <Grid item xs={12} sm={12} md={6} ls={6} xl={6}>
            <div>
              <p style={classess.mostPopular}>Most Popular</p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          paddingLeft='26px'
          paddingRight={'26px'}
          justifyContent={'center'}
          style={{ paddingTop: '32px' }}
        >
          {subscriptionList.slice().map((item) => {
            return (
              <Grid
                item
                key={item.stripePrice}
                xs={12}
                sm={12}
                md={6}
                ls={4}
                xl={4}
              >
                <div style={classess.cardArea}>
                  <ZukuCard subscriptionInfo={item} />
                </div>
              </Grid>
            )
          })}
        </Grid> */}
      </div>
      <div className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
        Pricing plans for teams of all sizes
      </h2>
      {/* <p className="mt-6 max-w-2xl text-xl text-gray-500">
        Choose an affordable plan that's packed with the best features for engaging your audience, creating customer
        loyalty, and driving sales.
      </p> */}

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">${tier.price}</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon className="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={() => payment(tier.priceCode)}
              className={classNames(
                tier.mostPopular
                  ? 'bg-zuke-red text-white hover:bg-zuke-blue'
                  : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
                'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
              )}
            >
              {tier.cta}
            </button>
          </div>
        ))}
      </div>
    </div>
    </div>
    
  )
}
