import React from 'react'
import zukeIcon from '../../assets/icons/ZukuIcon.svg'
import '@fontsource/roboto'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const classes = {
  root: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '50hv',
  },
  welcomeText: {
    flexGrow: 1,
    alignSelf: 'center',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '300px',
    fontSize: '49px',
    lineWeight: '59',
    letterSpacing: '0.05em',
    color: '#262626',
  },
  footerIcon: {
    position: 'absolute',
    bottom: '10px',
  },
  actionButton: {
    width: '130px',
    height: '43px',
    backgroundColor: 'rgba(179, 16, 236, 1)',
    color: 'white',
    fontWeight: '300',
    lineHeight: 'normal',
    borderRadius: '0% !important'
  }
}
export default function WelcomePage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search)

  let navigate = useNavigate()

  const onClickNextButton = () => {
    if(params.has('project_id')) {
      navigate(`/sign-up-name?project_id=${params.get('project_id')}`)
    } else {
      navigate('/sign-up-name')
    }
  }

  return (
    <div style={{
      width: '100vw', height: '100vh', display: 'flex',
      flexGrow: 1,
      textAlign: 'center',
      alignItems: 'center',
      paddingTop: '50hv'
    }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} ls={12} xl={12}>
          <div style={classes.welcomeText}>Welcome to Zuke</div>
          <div>You are creating a free account. You will only be able to view projects that are shared with you.
          </div>
          <div>You can upgrade your account at any time to be able to add your own projects.</div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12} xl={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontSize: '20px' }}
            style={classes.actionButton}
            onClick={onClickNextButton}
          >
            Next
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          ls={12}
          xl={12}
          style={classes.footerIcon}
        >
          <img src={zukeIcon} className='sm:block hidden' alt="SVG logo image" />
        </Grid>
      </Grid>
    </div>
  )
}
