import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../auth'
import { CircularProgress, Snackbar } from '@mui/material'

export default function AddViewer(props) {
    const projectId = props.projectId

    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { user } = useAuth()
    const [viewers, setViewers] = useState([]);
    const [viewerIds, setViewerIds] = useState([]);
    const headers = {
        'Authorization': `Bearer ${user.user.jwt}`
    };
    const [status, setStatus] = useState({
        loading: false,
        isOpenSnackStatus: false,
        message: '',
    })

    const onCloseSnack = () => {
        setStatus({
          message: '',
          error: false,
          isOpenSnackStatus: false,
        })
      }

    const onChangeEmail = (e) => {
        let email = e.target.value.replace(/[^a-zA-Z0-9@_.-]/g, '').trim()
        const maxLength = 50
        if (email.length > maxLength) {
            email = email.substring(0, maxLength)
        }
        setEmail(email)
    }

    const getViewers = async () => {
        const headers = {
            'Authorization': `Bearer ${user.user.jwt}`
        };
        const singleProject = await axios.get(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
            { headers });
        const initialViewers = singleProject.data.users_permissions_users.map(user => {
            return user.email;
        });
        setViewers(initialViewers);
        const viewerIds = singleProject.data.users_permissions_users.map(user => {
            return user.id;
        });
        setViewerIds(viewerIds);
    }

    const addViewer = async (e, viewer) => {
        e.preventDefault()
        let shareWith;
        // if (!viewer.text || /^\s*$/.test(viewer.text)) {
        //   return;
        // }

        try {
            console.log("looking for user")
            shareWith = await axios.get(`${process.env.REACT_APP_BASE_URL}/users-permissions/search/${viewer}`,
                { headers });

        } catch (e) {
            console.log(e)

        }

        if (shareWith.data.length !== 0) {
            try {
                const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
                    {
                        users_permissions_users: [...viewerIds,
                        {
                            "id": shareWith.data[0].id
                        },
                        ]
                    }, { headers });
                    console.log('user exists')
                    // window.location.reload(false)


            } catch (e) {
                console.log(e)

            }
        } else {
            createUser()

        }
    };

    const createUser = async () => {
        const genPass = generatePassword()
        const username = email
        setStatus({ loading: true, error: false, message: '' })
        try {
            const newUser = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/auth/local/register`,
                {
                    phone: '',
                    email,
                    company: `${user.user.user.company}`,
                    password: genPass,
                    role: 3,
                    name: `${firstName} ${lastName}`,
                    username,
                }
            )
            await sendPasswordReset(email)
            console.log(newUser)
            try {
                const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`,
                    {
                        users_permissions_users: [...viewerIds,
                        {
                            "id": newUser.data.user.id
                        },
                        ]
                    }, { headers });


            } catch (e) {
                console.log(e)

            }
            setStatus({ loading: false })
            setFirstName('')
            setLastName('')
            setEmail('')
            setStatus({
                message: 'invite sent',
                error: false,
                isOpenSnackStatus: true,
              })

        } catch (e) {
            console.log(e)
        }

    }

    const generatePassword = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 12;
        var generatedPassword = "";

        for (var i = 0; i <= passwordLength; i++) {
            var char = Math.floor(Math.random() * chars.length + 1);
            generatedPassword += chars.charAt(char)
        }
        return generatedPassword
    }

    const sendPasswordReset = async (email) => {
        console.log('send email')
        console.log(email)
        axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, {
            email
        })
            .then(response => {
                console.log('Your user received an email');
            })
            .catch(error => {
                console.log('An error occurred:', error.response);
            });
    }

    useEffect(() => {
        getViewers();
    }, []);


    return (
        <form className="space-y-8 " data-cy="addViewerForm">
            <div className="pt-28">
                <div>
                    <h3 className="text-lg text-left font-medium leading-6 text-gray-900">Add Viewer</h3>
                    <p className="mt-1 text-sm text-left text-gray-500">The viewer will be sent an email to finish setting up their account. When they have done so, they will be added to your project.</p>
                </div>
                <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-1 sm:grid-cols-12">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="text-left block text-sm font-medium text-gray-700">
                            First Name
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                autoComplete="First Name"
                                placeholder="First Name"
                                onChange={(event) => setFirstName(event.target.value)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                data-cy="firstName"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="text-left block text-sm font-medium text-gray-700">
                            Last Name
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                autoComplete="Last Name"
                                placeholder="Last Name"
                                onChange={(event) => setLastName(event.target.value)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                data-cy="lastName"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="email" className="text-left block text-sm font-medium text-gray-700">
                            Email Address
                        </label>
                        <div className="mt-1">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                placeholder="email address"
                                onChange={onChangeEmail}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                data-cy="email"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-center">
                    <button
                        onClick={e => addViewer(e, email)}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-zuke-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        data-cy="addViewer"
                    >
                        ADD VIEWER
                    </button>
                </div>
            </div>
            <Snackbar
        message={status.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => onCloseSnack(false)}
        open={status.isOpenSnackStatus}
      />
        </form>
        
    )
}
